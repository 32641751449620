// src/components/FAQ.js

import React, { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const HelpCenterNote = ({ date, title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="release-note-container">
      <div
        className="release-note-header"
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer" }}
      >
        <div className="release-toggle">
          <ArrowForwardIosSharpIcon
            style={{ width: "1rem" }}
            className={expanded ? "rotate-icon" : ""}
          />
        </div>

        <div className="release-note-title">{title}</div>
      </div>

      {expanded && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const isEmberHIPAA = (
    <p>
      Yes, Ember Copilot adheres to the highest standards of HIPAA compliance,
      ensuring that all data handling meets necessary healthcare regulations.
    </p>
  );

  const EmberBAA = (
    <p>
      A Business Associate Agreement (BAA) is a contract between a healthcare
      provider and a service provider that ensures the latter handles protected
      health information (PHI) in a compliant manner. Ember signs a BAA with
      each clinician using its service.
    </p>
  );

  const healthcareMgmt = (
    <p>
      Details about software compatibility and integration can be obtained by
      contacting Ember's technical support team.
    </p>
  );

  const EmberAccuracy = (
    <p>
      Ember Copilot boasts an impressive accuracy rate of clincial sessions.
      This high level of precision is due to its advanced capabilities in
      recognizing medical terminology, as well as its adeptness at handling
      various languages and accents, ensuring therapists receive reliable and
      accurate transcripts of their sessions.
    </p>
  );

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Frequently Asked Questions</h1>
        <HelpCenterNote title="Is Ember Copilot compliant with HIPAA?">
          {isEmberHIPAA}
        </HelpCenterNote>
        <HelpCenterNote title="What is a Business Associate Agreement (BAA) and does Ember offer one?">
          {EmberBAA}
        </HelpCenterNote>
        <HelpCenterNote title="Can Ember Copilot be integrated with other healthcare management software?">
          {healthcareMgmt}
        </HelpCenterNote>
        <HelpCenterNote title="How accurate is Ember Copilot in transcribing clinical sessions?">
          {EmberAccuracy}
        </HelpCenterNote>
      </div>
    </div>
  );
};

export default FAQ;

import React from "react";
// import healthcareOverview from "./../HealthcareOverview.png";
import productOverview from "./../product-overview.png";

const Product = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Meet Ember: Your AI Conversation Copilot</h2>
          <img
            className="product-overview"
            src={productOverview}
            alt="Product Overview"
          />
        </div>
        <div className="cta-btn-container">
          <div className="end-btn-wrapper">
            <button className="get-started-btn" onClick={handleButtonClick}>
              Get Started
            </button>
            <button className="schedule-demo-btn" onClick={handleScheduleClick}>
              Schedule a Demo
            </button>
          </div>
        </div>
        <div className="product-blocks-grid">
          <div className="product-block">
            <h2>Fast and Accurate</h2>
            <p>
              Embrace unmatched speed and accuracy with Ember. This AI copilot
              processes lengthy discussions swiftly, capturing every detail with
              precision. It's the ideal solution for sectors like healthcare,
              where accuracy is crucial for patient care and compliance.
            </p>
          </div>
          <div className="product-block">
            <h2>User-Friendly Interface</h2>
            <p>
              Experience the simplicity of Ember's design. Its intuitive
              interface allows users of any technical skill level to operate the
              system effectively without prior training, enhancing productivity
              from day one.
            </p>
          </div>
          <div className="product-block">
            <h2>Advanced Security and Privacy</h2>
            <p>
              Security is a cornerstone of Ember's functionality. Operating on a
              HIPAA-compliant infrastructure and adhering to SOC 2 standards,
              Ember ensures the highest level of data protection. Its
              confidential mode provides an extra layer of security, crucial for
              professionals in fields like healthcare, where privacy is not just
              a necessity but a legal requirement.
            </p>
          </div>
          <div className="product-block">
            <h2>In-depth Analysis</h2>
            <p>
              Ember goes beyond transcription, offering in-depth analysis of
              conversations. It identifies key points and action items, making
              meetings more productive and decisions more data-driven. This
              analytical prowess ensures that no critical information is
              overlooked.
            </p>
          </div>
        </div>
        <div className="usecase-second-block">
          <div className="end-product-block">
            <h2 className="image-wrapper">Ember is more than just a tool</h2>
            <p>
              It's your essential partner for managing professional
              conversations efficiently and securely. Choose Ember for a secure,
              accurate, and user-friendly way to make every meeting and
              discussion more productive and every decision better informed.
            </p>
            <div className="cta-btn-container">
              <div className="end-btn-wrapper">
                <button className="get-started-btn" onClick={handleButtonClick}>
                  Get Started
                </button>
                <button
                  className="schedule-demo-btn"
                  onClick={handleScheduleClick}
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;

import React from "react";
import demoGif from "../PlaychatDemo.gif";
import ios from "./../app-store-download.svg";
import emberLogo from "./../EmberAI.png";

const Demo = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };

  const handleAppStoreDownloadButtonClick = () => {
    window.location.href =
      "https://apps.apple.com/us/app/ember-ai/id6478905562";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <div className="demo-btn-container">
          <div className="demo-header">
            <img src={emberLogo} alt="Ember Copilot" />
          </div>
          <div className="btn-wrapper">
            <button
              type="submit"
              className="demo-waitlist-btn"
              onClick={handleButtonClick}
            >
              Try Now
            </button>
          </div>
        </div>

        <div className="justify-content-center align-items-center">
          <img className="demo-wrapper" src={demoGif} alt="Demo GIF" />
        </div>

        <div className="demo-btn-container">
          <div>
            <img
              className="download-ios"
              src={ios}
              alt="Download on iOS"
              onClick={handleAppStoreDownloadButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;

import React from "react";
// import slack from "./../slack.png";
import google from "./../google.png";
import microsoft from "./../microsoft.png";
import zoom from "./../zoom.png";
import EmberMobile from "./../EmberMobile.png";
import EmberWeb from "./../EmberWeb.png";
import EmberCopilot from "./../EmberCopilot.png";
import emberLogo from "./../EmberAI.png";
import confidentialMode from "./../ConfidentialMode.png";
import easyToUse from "./../EasyToUse.png";
import easySharing from "./../EasySharing.png";
// import aimodes from "./../aimodes.png";
import "./../App.css";

function Home() {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai/";
  };

  // const handleScheduleClick = () => {
  //   window.location.href = "https://calendly.com/embercopilot/30min";
  // };

  // const handleAppStoreDownloadButtonClick = () => {
  //   window.location.href =
  //     "https://apps.apple.com/us/app/ember-ai/id6478905562";
  // };

  const plans = [
    {
      name: "Mobile",
      features: [
        "Great when you're not at your desk",
        "Best for in-person meetings",
        "Notes are available on Ember Web",
      ],
      image: EmberMobile,
      btncss: "business",
      buttonLink: "https://apps.apple.com/us/app/ember-ai/id6478905562",
      buttonLabel: "Download on iOS",
    },
    {
      name: "Web",
      features: [
        "Works on all web browsers",
        "Best to manage your meetings",
        "No install required",
      ],
      image: EmberWeb,
      btncss: "business",
      buttonLink: "https://app.embercopilot.ai",
      buttonLabel: "Try it now",
    },
    {
      name: "Copilot",
      features: [
        "Joins your meeting as a notetaker",
        "Best for conference calls",
        "No extra tab",
      ],
      image: EmberCopilot,
      btncss: "pro",
      buttonLink: "https://app.embercopilot.ai",
      buttonLabel: "Try it now",
    },
  ];

  return (
    <div className="app-container">
      <div className="main-wrapper">
        <div className="main-tagline-block">
          <div className="above-the-fold">
            <div className="main-tagline">
              <div className="mobile-logo">
                <img src={emberLogo} alt="Ember Copilot" />
              </div>
              <div>HIPAA Compliant</div>
              <div>Medical Scribe</div>
            </div>
          </div>
          <div className="btn-container">
            <div className="btn-wrapper">
              <button
                type="submit"
                className="waitlist-btn"
                onClick={handleButtonClick}
              >
                Start for free
              </button>
            </div>
          </div>
        </div>
        <div className="below-the-fold">
          <div className="homepage-headers">Why Choose Ember?</div>
          <div
            style={{
              marginTop: "1rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            <p className="integration-text">Works with</p>
            <div className="integration-logos">
              <img src={google} alt="Google" className="google-logo" />
              <img src={microsoft} alt="Microsoft" className="msft-logo" />
              <img src={zoom} alt="Zoom" className="zoom-logo" />
              {/* <img src={slack} alt="Slack" className="slack-logo" /> */}
            </div>
            {/* <a
              href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#333", textDecoration: "none" }}
            >
              HIPAA and SOC 2 Compliance
            </a> */}
          </div>
          <div className="features-first-block">
            <div>
              <h2>End-to-End Encyption</h2>
              <p>
                All data captured by Ember is encrypted at rest and
                in-transmission using end-to-end AES-256 technology. Ember's
                confidential mode allows users to engage in discussions with
                utmost security.
              </p>
              <h2>100% HIPAA Compliant</h2>
              <p>
                Ensuring the highest level of data protection, Ember is 100%
                HIPAA and SOC 2 compliant, featuring a confidential mode for
                ultimate privacy.
              </p>
            </div>
            <div>
              <img src={confidentialMode} alt="Confidential Mode" />
            </div>
          </div>
          <div className="features-second-block">
            <div className="instant-results-block">
              <div className="image-wrapper">
                <img src={easyToUse} alt="Easy to Use" />
              </div>
              <h2>Easy to Use</h2>
              <p>
                Ember's intuitive interface that requires no prior training,
                enabling users of all technical levels to effortlessly navigate
                its features and enhance their meeting experience.
              </p>
            </div>
            <div className="easy-sharing-block">
              <h2>Efficiency Enhancement</h2>
              <p>
                By integrating seamlessly into daily workflows, Ember boosts
                productivity, ensuring meetings are more efficient and
                communication is managed more effectively.
              </p>
              <div className="image-wrapper">
                <img src={easySharing} alt="Easy Sharing" />
              </div>
            </div>
          </div>
          {/* <div className="features-first-block">
            <div>
              <h2>Fast and Accurate Processing</h2>
              <p>
                Transcribing and summarizing even hour-long meetings within
                seconds. Ember delivers polished, actionable transcripts with
                unmatched speed and accuracy.
              </p>
              <h2>Advanced Analysis Capabilities</h2>
              <p>
                Ember goes beyond simple transcription by providing detailed
                analyses, identifying critical issues, and outlining necessary
                action items tailored to specific needs.
              </p>
            </div>
            <div>
              <img src={aimodes} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div> */}
          {/* <div className="end-btn-container">
            <div className="end-btn-wrapper">
              <button className="get-started-btn" onClick={handleButtonClick}>
                Get Started
              </button>
              <button
                className="schedule-demo-btn"
                onClick={handleScheduleClick}
              >
                Schedule a Demo
              </button>
            </div>
          </div> */}
        </div>

        <div className="homepage-headers">Available Everywhere</div>
        <div className="available-container">
          {plans.map((plan) => (
            <div key={plan.name} className="plan-card">
              <img src={plan.image} alt={plan.name} className="card-image" />
              <div className="available-column">
                <div className="plan-header" style={{ textAlign: "center" }}>
                  {plan.name}
                </div>
                <ul className="custom-pricing-bullet pricing-text">
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button
                  className={`price-btn variant-${plan.btncss}`}
                  onClick={() => window.open(plan.buttonLink, "_blank")}
                >
                  {plan.buttonLabel}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;

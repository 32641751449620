// src/components/Healthcare.js

import React from "react";
import execsTop from "./../ExecsTop.png";
import remoteTeamView from "./../RemoteTeamView.png";
import remoteTeam from "./../remoteTeam.png";
import shareMeetings from "./../shareMeetings.png";

const Remote = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Ember for Remote Team</h2>
        </div>
        <div className="image-wrapper">
          <img
            className="usecase-hero-image"
            src={remoteTeam}
            alt="Remote Team"
          />
        </div>
        <button
          className="usecase-btn"
          onClick={handleButtonClick}
          style={{ margin: "auto", marginTop: "1.5rem" }}
        >
          Start for free
        </button>
        <div className="below-the-fold">
          <div className="execs-first-block">
            <div>
              <h2>Simply bring your team’s productivity to the next level</h2>

              <ul>
                <li>
                  Quickly share meeting summaries and action items with your
                  remote team, ensuring everyone is on the same page regardless
                  of location.
                </li>
                <li>
                  Benefit from Ember's easy-to-use interface, which simplifies
                  the management of meeting recordings.
                </li>
                <li>
                  Utilize Ember to highlight key decisions and discussion
                  points, enhancing follow-up efficiency.
                </li>
              </ul>

              <button
                className="usecase-btn"
                onClick={handleButtonClick}
                style={{ marginLeft: "1.5rem" }}
              >
                Start for free
              </button>
            </div>
            <div>
              <img src={execsTop} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="product-overview"
                  src={remoteTeamView}
                  alt="Remote Team Overview"
                />
              </div>
              <h2 className="image-wrapper">Improve Team Productivity</h2>
              <p className="image-wrapper">
                <ul>
                  <li>
                    Use Ember’s analytics to understand team productivity and
                    optimize remote workflows.
                  </li>
                  <li>
                    Reduce the time spent on preparing for meetings by accessing
                    meeting prep and notes in Ember.
                  </li>
                </ul>
              </p>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Work Smarter, Together
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="execs-first-block">
            <div>
              <h2>Easy Streamline</h2>
              <p>
                <ul>
                  <li>
                    Organize all your remote communications in one place, making
                    it easy for team members to access and review as needed.
                  </li>
                  <li>
                    Share easily accessible links to recordings and transcripts,
                    allowing team members to catch up quickly if they miss a
                    meeting.
                  </li>
                </ul>
              </p>
              <div>
                <button className="usecase-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
              </div>
            </div>
            <div>
              <img src={shareMeetings} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block" style={{ marginTop: "4rem" }}>
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Transform Remote Challenges into Productivity Triumphs
              </h2>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button
                    className="remote-team-start-btn"
                    onClick={handleButtonClick}
                  >
                    Get Started
                  </button>
                  <button
                    className="work-together-btn"
                    onClick={handleScheduleClick}
                  >
                    Work Smarter, Together
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remote;

// src/components/Terms.js

import React from "react";

const Terms = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Terms of Service for Ember Copilot</h1>
        <p>
          <p>Effective Date: March 13, 2024</p>
          <p>Welcome to Ember Copilot!</p>
          <p>
            Your privacy and data security are paramount to us. This document
            outlines our practices concerning the collection, use, and
            protection of your personal information when you engage with the
            Ember Copilot application ("App"). By using our App, you consent to
            the policies and practices described herein.
          </p>
          <h2>1. Information We Collect</h2>
          <ul>
            <li>
              Account Information: Email address and password during the account
              creation process.
            </li>
            <li>
              Audio Recordings: Recordings made within the App for transcription
              and story generation.
            </li>
            <li>
              Usage Data: Interactions with the App to guide improvements and
              user experience enhancements.
            </li>
            <li>
              System Performance Data: Information on App performance, including
              crash reports.
            </li>
            <li>
              Third-Party Data: Our App may receive data from third-party
              sources during standard interactions or integrations with our
              services, such as IDs from slash command payloads and data from
              system logging. Please note that while we receive this third-party
              data, we do not use it for any purpose.
            </li>
          </ul>
          <h2>2. How We Use Your Information</h2>
          <p>
            Your data supports the provision, maintenance, and enhancement of
            our services, including:
            <ul>
              <li>Processing audio recordings for transcription.</li>
              <li>
                Utilizing usage and performance data to improve App
                functionality and resolve issues
              </li>
              <li>
                Ensuring that any third-party data received but not used is
                handled with the same level of security and privacy as directly
                collected data.
              </li>
            </ul>
          </p>
          <h2>3. Data Protection</h2>
          <ul>
            <li>
              Encryption: All user data is encrypted both in transit and at
              rest.
            </li>
            <li>
              Retention: Data is held only as long as necessary for service
              provision and legal compliance.
            </li>
            <li>
              No Sensitive Data Collection: We avoid collecting sensitive
              personal information.
            </li>
            <li>
              Protected Health Information (PHI): We will process any PHI
              included in your data in accordance with the Business Associate
              Agreement attached hereto as Schedule A (“BAA”).
            </li>
          </ul>
          <h2>4. User Rights</h2>
          <ul>
            <li>
              Access and Control: You may review, amend, or delete your personal
              information upon request.
            </li>
            <li>
              Opt-Out: Users can opt out of data collection practices not
              essential to App functionality.
            </li>
            <li>
              Data Request: Individuals wishing to access, transfer, or delete
              their data, including any third-party data inadvertently received,
              can contact us.
            </li>
          </ul>
          <h2>5. Cookies and Tracking</h2>
          <p>
            We use cookies and similar technologies to enhance your experience
            and gather information about visitors and visits to our websites.
            You can manage your cookie preferences within your browser settings.
          </p>
          <h2>6. Data Sharing and Disclosure</h2>
          <p>
            We do not share your personal data with third parties, except as
            required by law or to protect our rights.
          </p>
          <h2>7. Changes to This Policy</h2>
          <p>
            Any updates to our Terms will be communicated through our App and
            website, with the Effective Date reflecting the latest revision.
          </p>
          <h2>8. Contact Us</h2>
          <p>
            For questions or concerns about our terms, please contact us at
            support at embercopilot dot ai.
          </p>
          <h2>Your Consent</h2>
          <p>
            By accessing and using Ember Copilot, you agree to the terms
            outlined in our Terms of Service.
          </p>
          <div className="schedule-a-container">
            <h1>Schedule A: Business Associate Agreement</h1>
            <p>
              This Business Associate Agreement (“BAA”) is by and between
              Wisdom, Inc. (“Business Associate”), and Customer (“Covered
              Entity”), and is effective as of Effective Date.
            </p>
            <p>
              WHEREAS, pursuant to the Terms of Use, Business Associate will
              provide certain services to, for, or on behalf of Covered Entity
              involving the use or disclosure of Protected Health Information
              ("PHI"), and pursuant to such Terms of Use, Business Associate may
              be considered a "business associate" of Covered Entity; and
            </p>
            <p>
              WHEREAS, Covered Entity and Business Associate intend to protect
              the privacy and provide for the security of PHI disclosed to
              Business Associate pursuant to the Provider Agreement in
              compliance with the Health Insurance Portability and
              Accountability Act of 1996, Public Law 104-191 ("HIPAA") and the
              Standards for Privacy of Individually Identifiable Health
              Information promulgated thereunder by the U.S. Department of
              Health and Human Services at 45 CFR § 160 and § 164 (the "HIPAA
              Rules"), and the Health Information Technology for Economic and
              Clinical Health Act of 2009 (the "HITECH Act"), in each case as
              amended from time to time; and
            </p>
            <p>
              WHEREAS, the purpose of this BAA is to satisfy certain standards
              and requirements of the HIPAA Rules and the HITECH Act, as the
              same may be amended from time to time.
            </p>
            <p>
              NOW, THEREFORE, in consideration of the mutual promises below and
              the exchange of information pursuant to this BAA, the parties
              agree as follows:
            </p>
            <h3>1. Definitions.</h3>
            <p>
              Terms used but not otherwise defined in this BAA shall have the
              same meaning as set forth in 45 CFR Parts 160, 162 and 164, or the
              HITECH Act.
            </p>
            <h3>2. Obligations of Business Associate.</h3>
            <ol type="a">
              <li>
                Permitted Uses and Disclosures. Business Associate agrees to
                only Use or Disclose PHI as necessary in order to perform the
                services set forth in the Provider Agreement, as permitted under
                this BAA, or as Required by Law. Business Associate shall have
                the right to de-identify any and all PHI, provided that Business
                Associate implements a de-identification process that conforms
                to the requirements of 45 C.F.R. 164.514(a)-(c) ("De-identified
                Data"). Business Associate may Use or Disclose such
                De-identified Data to third parties at its discretion, as such
                De-identified Data does not constitute PHI and is not subject to
                the terms of this BAA. Business Associate shall own all right,
                title and interest in and to such De-identified Data.
              </li>
              <li>
                Nondisclosure. Business Associate shall not Use or further
                Disclose PHI other than as permitted or required by this BAA.
              </li>
              <li>
                Safeguards. Business Associate shall use appropriate
                administrative, technical, and physical safeguards to prevent
                the use or disclosure of PHI other than as provided for by this
                BAA. Business Associate shall maintain a comprehensive written
                information privacy and security program that includes
                administrative, technical, and physical safeguards appropriate
                to the size and complexity of the Business Associate's
                operations and the nature and scope of its activities.
              </li>
              <li>
                Reporting of Disclosures; Mitigation. Business Associate shall
                report to Covered Entity any use or disclosure of PHI not
                provided for by this BAA of which Business Associate becomes
                aware. Business Associate agrees to mitigate, to the extent
                practicable, any harmful effect that is known to Business
                Associate of a use or disclosure of PHI by Business Associate in
                violation of the requirements of this BAA.
              </li>
              <li>
                Business Associate's Agents. Business Associate shall ensure
                that any subcontractors, to whom it provides PHI received from
                (or created or received by Business Associate on behalf of)
                Covered Entity agree to the same restrictions and conditions
                that apply to Business Associate with respect to such PHI.
              </li>
              <li>
                Availability of Information to Covered Entity. Business
                Associate shall make available to Covered Entity (or, as
                directed by Covered Entity, to an Individual) such information
                as Covered Entity may request, and in the time and manner
                designated by Covered Entity, to fulfill Covered Entity's
                obligations (if any) to provide access to, provide a copy of,
                and account for disclosures with respect to PHI pursuant to
                HIPAA and the HIPAA Rules, including, but not limited to, 45 CFR
                §§ 164.524 and 164.528. Requests for information must be
                submitted at least 14 days in advance of the due date.
              </li>
              <li>
                Amendment of PHI. Business Associate shall make any amendments
                to PHI in a Designated Record Set that the Covered Entity
                directs or agrees to at the request of Covered Entity or an
                Individual, and in the time and manner designated by Covered
                Entity, to fulfill Covered Entity's obligations (if any) to
                amend PHI pursuant to HIPAA and the HIPAA Rules, including, but
                not limited to, 45 CFR § 164.526, and Business Associate shall,
                as directed by Covered Entity, incorporate any amendments to PHI
                into copies of such PHI maintained by Business Associate.
              </li>
              <li>
                Internal Practices. Business Associate shall make its internal
                practices, books and records relating to the use and disclosure
                of PHI received from Covered Entity (or created or received by
                Business Associate on behalf of Covered Entity) available to the
                Secretary, in a time and manner designated by Covered Entity or
                the Secretary, for purposes of the Secretary determining Covered
                Entity's compliance with HIPAA and the HIPAA Rules.
              </li>
              <li>
                Documentation of Disclosures for Accounting. Business Associate
                agrees to document such disclosures of PHI and information
                related to such disclosures as would be required for Covered
                Entity to respond to a request by an Individual for an
                accounting of disclosures of PHI in accordance with 45 CFR §
                164.528.
              </li>
              <li>
                Access to Documentation for Accounting. Business Associate
                agrees to provide to Covered Entity or an Individual, in a time
                and manner designated by Covered Entity, information documented
                in accordance with Section 2(i) of this BAA in a time and manner
                as to permit Covered Entity to respond to a request by an
                Individual for an accounting of disclosures of PHI in accordance
                with 45 CFR § 164.528.
              </li>
              <li>
                Notification of Breach. During the Term of this BAA, Business
                Associate shall notify Covered Entity within ten (10) days of
                Discovery of any Breach of Unsecured PHI. Business Associate
                further agrees, consistent with Section 13402 of the HITECH Act,
                to provide Covered Entity with information necessary for Covered
                Entity to meet the requirements of said section, and in a manner
                and format to be specified by Covered Entity.
              </li>
              <li>
                Minimum Necessary. When using, disclosing, or requesting PHI
                from the Covered Entity, or in accordance with any provision of
                this BAA, Business Associate shall limit PHI to the minimum
                necessary to accomplish the intended purpose of the use,
                disclosure, or request.
              </li>
            </ol>
            <h3>3. Obligations of Covered Entity.</h3>
            <ol type="a">
              <li>
                Covered Entity shall be responsible for using appropriate
                safeguards to maintain and ensure the confidentiality, privacy
                and security of PHI transmitted to Business Associate pursuant
                to the BAA and this BAA, in accordance with the standards and
                requirements of HIPAA and the HIPAA Rules, until such PHI is
                received by Business Associate.
              </li>
              <li>
                Upon request, Covered Entity shall provide Business Associate
                with the notice of privacy practices that Covered Entity
                produces in accordance with 45 CFR § 164.520, as well as any
                changes to such notice.
              </li>
              <li>
                Covered Entity shall provide Business Associate with any changes
                in, or revocation of, permission by an Individual to use or
                disclose PHI, if such changes affect Business Associate's
                permitted or required uses or disclosures.
              </li>
              <li>
                Covered Entity shall notify Business Associate of any
                restriction to the use or disclosure of PHI that Covered Entity
                has agreed to in accordance with 45 CFR § 164.522, if such
                restriction affects Business Associate's permitted or required
                uses or disclosures.
              </li>
            </ol>
            <h3>4. Term and Termination.</h3>
            <ol type="a">
              <li>
                Term. The Term of this BAA shall become effective as of the
                Effective Date and shall terminate when all of the PHI provided
                by Covered Entity to Business Associate, or created or received
                by Business Associate on behalf of Covered Entity, is destroyed
                or returned to Covered Entity, or, if it is infeasible to return
                or destroy PHI, protections are extended to such information, in
                accordance with the termination provisions of this Section and
                Section 16 of the Terms of Use. The provisions of this BAA shall
                survive termination of the BAA to the extent necessary for
                compliance with HIPAA and the HIPAA Rules.
              </li>
              <li>
                Material Breach. A material breach by either party of any
                provision of this BAA shall constitute a material breach of the
                BAA, if such breach is not cured by the breaching party within
                thirty (30) days of receipt of notice describing the material
                breach.
              </li>
              <li>
                Reasonable Steps to Cure Breach. If either party learns of an
                activity or practice of the other party that constitutes a
                material breach or violation of the other party's obligations
                under the provisions of this BAA, then the non-breaching party
                shall notify the breaching party of the breach and the breaching
                party shall take reasonable steps to cure such breach or
                violation, as applicable, within a period of time which shall in
                no event exceed thirty (30) days. If the breaching party's
                efforts to cure such breach or violation are unsuccessful, the
                non-breaching party shall either terminate the BAA, if feasible,
                or if termination of the BAA is not feasible and the breaching
                party has violated the HIPAA Rules, the non-breaching party may
                report the breaching party's breach or violation to the
                Secretary.
              </li>
              <li>
                Judicial or Administrative Proceedings. Either party may
                terminate the BAA, effective immediately, if the other party is
                named as a defendant in a criminal proceeding for an alleged
                violation of HIPAA, or a finding or stipulation that the other
                party has violated any standard or requirement of HIPAA or other
                security or privacy laws is made in any administrative or civil
                proceeding in which the party has been joined.
              </li>
              <li>
                Effect of Termination.
                <ol type="i">
                  <li>
                    Except as provided in paragraph (e)(2) of this Section or if
                    required by law or regulation to be maintained by Business
                    Associate, upon termination of the BAA for any reason,
                    Business Associate shall return at Covered Entity's expense,
                    or destroy all PHI received from Covered Entity (or created
                    or received by Business Associate on behalf of Covered
                    Entity) that Business Associate still maintains in any form,
                    and shall retain no copies of such PHI. This provision shall
                    apply to PHI that is in the possession of subcontractors or
                    agents of Business Associate.
                  </li>
                  <li>
                    In the event that Business Associate determines that
                    returning or destroying the PHI is infeasible, Business
                    Associate shall provide to Covered Entity notification of
                    the conditions that make return or destruction infeasible.
                    Upon mutual agreement of the parties that return or
                    destruction of PHI is infeasible, Business Associate shall
                    extend the protections of this BAA to such PHI and limit
                    further uses and disclosures of such PHI to those purposes
                    that make the return or destruction infeasible, for so long
                    as Business Associate maintains such PHI. The obligations of
                    Business Associate under this Section shall survive the
                    termination of the BAA.
                  </li>
                </ol>
              </li>
            </ol>
            <h3>5. Amendment to Comply with Law.</h3>
            <p>
              The parties acknowledge that state and federal laws relating to
              electronic data security and privacy are rapidly evolving and that
              amendment of the BAA may be required to provide for procedures to
              ensure compliance with such developments. The parties specifically
              agree to take such action as is necessary to implement the
              standards and requirements of HIPAA, the HIPAA Rules, the HITECH
              Act, and other applicable laws relating to the security or
              confidentiality of PHI. Upon the request of either party, the
              parties shall promptly enter into negotiations concerning the
              terms of an amendment to the BAA embodying written assurances
              consistent with the standards and requirements of HIPAA, the HIPAA
              Rules, the HITECH Act, or other applicable laws relating to
              security and privacy of PHI. Either party may terminate the BAA
              upon thirty (30) days' written notice in the event the other party
              does not promptly enter into negotiations to amend the BAA when
              requested pursuant to this Section, or does not enter into an
              amendment to the BAA providing assurances regarding the
              safeguarding of PHI that satisfy the standards and requirements of
              HIPAA, the HIPAA Rules, the HITECH Act, or any other applicable
              laws relating to security and privacy of PHI.
            </p>
            <h3>6. No Third Party Beneficiaries.</h3>
            <p>
              Nothing in this BAA is intended to confer, nor shall anything
              herein confer, upon any person other than Covered Entity, Business
              Associate and their respective successors and assigns, any rights,
              remedies, obligations or liabilities whatsoever and no other
              person or entity shall be a third party beneficiary of this BAA.
            </p>
            <h3>7. Effect on BAA.</h3>
            <p>
              Except as specifically required to implement the purposes of this
              BAA, or to the extent inconsistent with this BAA, all other terms
              of the BAA shall remain in full force and effect.
            </p>
            <h3>8. Interpretation.</h3>
            <p>
              This BAA shall be interpreted as broadly as necessary to implement
              and comply with HIPAA, the HIPAA Rules and any other applicable
              law relating to security and privacy of PHI. Any ambiguity in this
              BAA shall be resolved in favor of a meaning that permits Covered
              Entity to comply with the HIPAA Rules.
            </p>
            <h3>9. Regulatory References.</h3>
            <p>
              A reference in this BAA to a section in the HIPAA Rules or the
              HITECH Act means the section as in effect or as amended, and for
              which compliance is required.
            </p>
          </div>
          <b>© 2024 Ember Copilot. All rights reserved.</b>
        </p>
      </div>
    </div>
  );
};

export default Terms;

// src/components/ConnectSlack.js

import React from "react";

const ZoomQuickstart = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <div style={{ textAlign: "center" }}>
          <h1>Ember Copilot for Zoom</h1>
        </div>

        <div className="product-block">
          <h2>Installation</h2>
          <ol type="1">
            <li>
              Sign in to Ember on{" "}
              <a
                className="cta-link"
                href="https://app.embercopilot.ai"
                target="_blank"
                rel="noreferrer"
              >
                Web
              </a>
            </li>

            <li>
              Connect to Zoom
              <a
                href="https://app.embercopilot.ai/connect_zoom"
                target="_blank"
                rel="noreferrer"
                className="cta-link"
              >
                {" "}
                here
              </a>
            </li>
            <li>
              Confirm permissions, click <b>Allow</b>
            </li>
            <li>
              You'll be redirected back to Ember. Follow the next video to start
              recording with Ember
            </li>
          </ol>
        </div>
        <div className="product-block">
          <h2>Usage</h2>
          <iframe
            className="video-overview"
            src="https://www.youtube.com/embed/w4_Z3meoRjE"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Product Overview"
          ></iframe>
        </div>
        <div className="product-block">
          <h2>Uninstallation</h2>
          <ol type="1">
            <li>
              Login to your Zoom account and navigate to the Zoom Marketplace
            </li>
            <li>
              Click <b>Manage &gt; Installed Apps</b> or search for the Ember
              app
            </li>
            <li>
              Click <b>Uninstall</b> next to Ember
            </li>
            <li>
              Confirm dialogue, click <b>Uninstall</b>
            </li>
          </ol>
        </div>
        <div className="product-block">
          <h2>Support</h2>
          <p>
            You can reach us by sending an email to support@embercopilot.ai. We
            look forward to chatting with you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZoomQuickstart;

// src/components/Pricing.js

import React, { useState } from "react";

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = [
    {
      name: "Free",
      price: "Free",
      description: "Try Ember in the easiest way",
      prereq: "    ",
      features: ["5 Recordings", "Transcription", "Progress Notes"],
      btncss: "basic",
      annualPrice: "Free",
      buttonLink: "https://app.embercopilot.ai/",
      annualPlanLink: "https://app.embercopilot.ai/",
      buttonLabel: "Try for Free",
    },
    {
      name: "Individual",
      price: "$49.99 USD",
      unit: "per user/month",
      annualUnit: "per user/month, billed annually",
      description: "Unlimited notes",
      prereq: "Includes Free Features+",
      features: [
        "Unlimited Recordings",
        "Download Audio / Video",
        "Treatment Plan",
      ],
      btncss: "pro",
      buttonLink: "https://buy.stripe.com/dR6cOC3B6fGn3egbIS",
      buttonpromo: "No Credit Card Required",
      buttonLabel: "Get Started",
      annualPrice: "$40.99 USD",
      annualPlanText: "Annual Payment Discount",
      annualPlanLink: "https://buy.stripe.com/aEU4i6c7C3XF3eg14b",
    },
    {
      name: "Group",
      price: "",
      unit: "",
      annualUnit: "",
      description: "Advanced support and integration",
      prereq: "Includes Individual Features+",
      features: [
        "EHR Integration",
        "Dedicated Support",
        "Organization-wide BAA",
      ],
      btncss: "business",
      buttonLink: "https://calendly.com/embercopilot/30min?month=2024-06",
      buttonLabel: "Schedule a Demo",
      annualPrice: "",
      annualPlanText: "Schedule a Demo",
      annualPlanLink: "https://calendly.com/embercopilot/30min?month=2024-06",
    },
  ];

  // Modify plans based on isAnnual state
  const modifiedPlans = plans.map((plan) => ({
    ...plan,
    price: isAnnual ? plan.annualPrice : plan.price,
    buttonLink: isAnnual ? plan.annualPlanLink : plan.buttonLink,
    unit: isAnnual ? plan.annualUnit : plan.unit,
    // Other modifications for annual plans go here
  }));

  const togglePricing = (annual) => {
    setIsAnnual(annual);
  };

  const demo = [
    {
      name: "Free",
      meetingAssistant: {
        meetingTools: "5 Sessions",
        maxRecordingDuration: "60 Minutes",
        meetingNotes: "5 Sessions",
        audioPlayback: "30 Days",
        playbackSpeed: false,
        history: "5 Sessions",
        uploadPreRecorded: false,
        meetingBots: true,
        customKeywords: "",
      },
      reportAnalysis: {
        advancedSearching: true,
        transcription: true,
        meetingOutlines: false,
        summary: true,
        actionItems: true,
        aiNotes: false,
        askEmberAI: false,
      },
      collaboration: {
        oneClickDelivery: true,
        multilingualSupport: false,
        audioSharing: false,
        ShareViaLink: false,
        Integration: false,
        ShareInChannel: false,
      },
      support: {
        emailSupport: true,
        inAppSupport: "",
        onboarding: false,
        domainCapture: false,
        dataRetentionPolicy: false,
        licenseManagement: false,
        hipaa: true,
        soc2: true,
        organizationWideBAA: false,
      },
    },
    // {
    //   name: "Ember",
    //   meetingAssistant: {
    //     meetingTools: "10 Sessions / month",
    //     maxRecordingDuration: "1 hour",
    //     meetingNotes: "10 Sessions / month",
    //     audioPlayback: true,
    //     playbackSpeed: "Adjustable",
    //     history: "20 Most Recent",
    //     uploadPreRecorded: false,
    //     meetingBots: true,
    //     customKeywords: "100",
    //   },
    //   reportAnalysis: {
    //     advancedSearching: true,
    //     transcription: true,
    //     meetingOutlines: true,
    //     summary: true,
    //     aiNotes: true,
    //     actionItems: true,
    //     weeklySummary: true,
    //     askEmberAI: false,
    //   },
    //   collaboration: {
    //     oneClickDelivery: true,
    //     multilingualSupport: true,
    //     audioSharing: true,
    //     ShareViaLink: true,
    //     Integration: false,
    //     ShareInChannel: false,
    //   },
    //   support: {
    //     emailSupport: true,
    //     inAppSupport: "Standard",
    //     onboarding: false,
    //     domainCapture: false,
    //     dataRetentionPolicy: false,
    //   },
    // },
    {
      name: "Remember",
      meetingAssistant: {
        meetingTools: "Unlimited",
        maxRecordingDuration: "1 Hour",
        meetingNotes: "Unlimited",
        audioPlayback: "3 Months",
        playbackSpeed: "Adjustable",
        history: "Unlimited",
        uploadPreRecorded: false,
        meetingBots: true,
        customKeywords: false,
      },
      reportAnalysis: {
        advancedSearching: true,
        transcription: true,
        meetingOutlines: true,
        summary: true,
        aiNotes: true,
        actionItems: true,
        askEmberAI: true,
      },
      collaboration: {
        oneClickDelivery: true,
        multilingualSupport: true,
        audioSharing: true,
        shareViaLink: true,
        integration: false,
        shareInChannel: false,
      },
      support: {
        emailSupport: true,
        inAppSupport: "Prioritized",
        onboarding: false,
        domainCapture: false,
        dataRetentionPolicy: false,
        licenseManagement: false,
        hipaa: true,
        soc2: true,
        organizationWideBAA: false,
      },
    },
    {
      name: "Enterprise",
      meetingAssistant: {
        meetingTools: "Unlimited",
        maxRecordingDuration: "2 Hour",
        meetingNotes: "Unlimited",
        audioPlayback: "12 Months",
        playbackSpeed: "Adjustable",
        history: "Unlimited",
        uploadPreRecorded: true,
        meetingBots: true,
        customKeywords: "Custom",
      },
      reportAnalysis: {
        advancedSearching: true,
        transcription: true,
        meetingOutlines: true,
        summary: true,
        aiNotes: true,
        actionItems: true,
        weeklySummary: true,
        askEmberAI: true,
      },
      collaboration: {
        oneClickDelivery: true,
        multilingualSupport: true,
        audioSharing: true,
        shareViaLink: true,
        integration: true,
        shareInChannel: true,
      },
      support: {
        emailSupport: true,
        inAppSupport: "Prioritized",
        onboarding: true,
        domainCapture: true,
        dataRetentionPolicy: true,
        licenseManagement: true,
        hipaa: true,
        soc2: true,
        organizationWideBAA: true,
      },
    },
  ];
  return (
    <div className="pricing-background">
      <div className="pricing-header">Plans & Pricing</div>
      <div className="plan-selection">
        <button
          onClick={() => togglePricing(false)}
          className={isAnnual ? "" : "active"}
        >
          Monthly
        </button>
        <button
          onClick={() => togglePricing(true)}
          className={isAnnual ? "active" : ""}
        >
          Yearly
        </button>
      </div>
      <div className="pricing-container">
        {modifiedPlans.map((plan) => (
          <div className="feature-column" key={plan.name}>
            <div className="plan-header">{plan.name}</div>
            <p className="plan-description">{plan.description}</p>
            <p className="price" style={{ marginBottom: "1.5rem" }}>
              {plan.price}
            </p>
            <p className="plan-unit">{plan.unit}</p>
            <button
              className={`price-btn variant-${plan.btncss}`}
              onClick={() => window.open(plan.buttonLink, "_blank")}
            >
              {plan.buttonLabel}
            </button>
            {/* <p className="plan-promo">{plan.buttonpromo}</p> */}
            <p className="plan-prereq">{plan.prereq}</p>
            <ul className="custom-pricing-bullet pricing-text">
              {plan.features.map((feature, index) => (
                <li key={index}>
                  {feature === "Sales Call Analysis" && (
                    <span className="feature-badge">New</span>
                  )}
                  {feature === "View All Features" && (
                    <a
                      href="https://www.embercopilot.ai/changelog"
                      target="_blank"
                      rel="noreferrer"
                      className="cta-link"
                    >
                      {feature}
                    </a>
                  )}
                  {feature !== "View All Features" && feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="pricing-table-container">
        <table className="pricing-table">
          <thead>
            <tr>
              <th></th>
              {demo.map((plan) => (
                <th key={plan.index}>{plan.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[
              {
                category: "Meeting Assistant",
                items: [
                  { label: "Meeting Tools", key: "meetingTools" },
                  {
                    label: "Max Recording Duration",
                    key: "maxRecordingDuration",
                  },
                  { label: "Sessions Notes", key: "meetingNotes" },
                  {
                    label: "Audio & Video Playback",
                    key: "audioPlayback",
                  },
                  { label: "Playback Speed", key: "playbackSpeed" },
                  { label: "History", key: "history" },
                  {
                    label: "Meeting Bots",
                    key: "meetingBots",
                    checkmark: true,
                  },
                  {
                    label: "Upload Pre-recorded Audio / Video",
                    key: "uploadPreRecorded",
                    checkmark: true,
                  },

                  {
                    label: "Custom Keywords",
                    key: "customKeywords",
                    checkmark: true,
                  },
                ],
                dataKey: "meetingAssistant",
              },
              {
                category: "Meeting Report and Analysis",
                items: [
                  {
                    label: "Advanced Searching",
                    key: "advancedSearching",
                    checkmark: true,
                  },
                  {
                    label: "Transcription",
                    key: "transcription",
                    checkmark: true,
                  },
                  { label: "Visit Summary", key: "summary", checkmark: true },
                  {
                    label: "Progress Notes",
                    key: "actionItems",
                    checkmark: true,
                  },
                  {
                    label: "Treatment Plan",
                    key: "meetingOutlines",
                    checkmark: true,
                  },
                  {
                    label: "Ask Ember AI",
                    key: "askEmberAI",
                    checkmark: true,
                  },
                ],
                dataKey: "reportAnalysis",
              },
              {
                category: "Collaboration",
                items: [
                  {
                    label: "1-Click Delivery",
                    key: "oneClickDelivery",
                    checkmark: true,
                  },
                  {
                    label: "Multilingual Support",
                    key: "multilingualSupport",
                    checkmark: true,
                  },
                  {
                    label: "Audio Sharing / Download",
                    key: "audioSharing",
                    checkmark: true,
                  },
                  {
                    label: "Share via Link",
                    key: "shareViaLink",
                    checkmark: true,
                  },
                  {
                    label: "Calendar Integration",
                    key: "integration",
                    checkmark: true,
                  },
                  {
                    label: "EHR Integration",
                    key: "shareInChannel",
                    checkmark: true,
                  },
                ],
                dataKey: "collaboration",
              },
              {
                category: "Support and Security",
                items: [
                  {
                    label: "Email Support",
                    key: "emailSupport",
                    checkmark: true,
                  },
                  {
                    label: "In-App Support",
                    key: "inAppSupport",
                  },
                  {
                    label: "Onboarding",
                    key: "onboarding",
                    checkmark: true,
                  },
                  {
                    label: "SOC 2",
                    key: "soc2",
                    checkmark: true,
                  },
                  {
                    label: "HIPAA",
                    key: "hipaa",
                    checkmark: true,
                  },
                  {
                    label: "Organization-wide BAA",
                    key: "organizationWideBAA",
                    checkmark: true,
                  },
                  {
                    label: "License management",
                    key: "licenseManagement",
                    checkmark: true,
                  },
                ],
                dataKey: "support",
              },
            ].map((section) => (
              <>
                <tr className="category">
                  <td colSpan={4}>{section.category}</td>
                </tr>
                {section.items.map((item) => (
                  <tr key={item.key}>
                    <td>
                      {item.badge && (
                        <span className="feature-badge">{item.badge}</span>
                      )}
                      {item.label}
                    </td>
                    {demo.map((plan) => (
                      <td key={plan.index}>
                        {item.checkmark
                          ? plan[section.dataKey][item.key]
                            ? "✔️"
                            : ""
                          : plan[section.dataKey][item.key] || ""}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pricing-subheader">
        All plans come with a 30-day satisfaction guarantee.
      </div>
    </div>
  );
};

export default Pricing;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ReferralLanding.css";

const ReferralLanding = () => {
  const [referralCode, setReferralCode] = useState("");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code && code === "X7KP9Q") {
      setReferralCode(code);
    } else {
      window.location.href = "/";
    }
  }, [location]);

  const handleCTA = () => {
    window.open("https://buy.stripe.com/bIY4i65Je0LtdSUaES", "_blank");
  };

  return (
    <div className="referral-container">
      <h3 className="referral-title">
        You've been invited to try Ember Copilot!
      </h3>
      <p className="referral-text">
        Dr. Akhu thinks you'll love Ember Copilot, and we agree! As a special
        offer for Dr. Akhu's friends, you'll get your first month free.
      </p>
      <p className="referral-text">
        Ember Copilot is the AI Scribe that saves you 2+ hours daily on
        documentation. Let us take care of your progress notes, so you can focus
        on what matters most.
      </p>
      <p className="referral-code">
        Your unique referral code:{" "}
        <span className="referral-code-value">{referralCode}</span>
      </p>
      <div className="referral-cta-container">
        <button onClick={handleCTA} className="referral-cta-button">
          Claim Your Free Month
        </button>
      </div>
    </div>
  );
};

export default ReferralLanding;

// src/components/ConnectSlack.js

import React from "react";

const ConnectZoom = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <div style={{ textAlign: "center" }}>
          <h1>Ember Copilot for Zoom</h1>
          <iframe
            className="video-overview"
            src="https://www.youtube.com/embed/w4_Z3meoRjE"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Product Overview"
          ></iframe>
        </div>
        <div className="product-block product-spacing">
          <h2>Installation Steps</h2>
          <ol type="1">
            <li>
              Sign in to Ember on{" "}
              <a
                className="cta-link"
                href="https://app.embercopilot.ai"
                target="_blank"
                rel="noreferrer"
              >
                Web
              </a>
            </li>
            <li>Enter the Zoom link in meeting URL</li>
            <li>Ember will join your meeting and generate AI notes</li>
          </ol>
        </div>
        <div className="product-block product-spacing">
          <h2>Why Choose Ember?</h2>
          <ul>
            <li>
              <b>Multilingual Support: </b>Whether your team is local or global,
              Ember’s ability to record in any language makes it perfect for
              diverse and international settings.
            </li>
            <li>
              <b>Instant Transcription and Summarization: </b>Ember values your
              time, swiftly transcribing and summarizing your meetings, allowing
              you to focus on what matters most.
            </li>
            <li>
              <b>Comprehensive Analysis: </b>More than a transcription tool,
              Ember analyzes your discussions, highlighting crucial issues and
              action items to enhance meeting outcomes.
            </li>
            <li>
              <b>User-Friendly Interface: </b>Ember’s intuitive design lets you
              start improving your communications{" "}
              <b>without any prior training</b>.
            </li>
          </ul>
        </div>
        <div className="product-block product-spacing">
          <h2>Experience the Power of Ember</h2>
          <p>
            Ember is more than just a tool; it’s a revolution in managing and
            enhancing professional communications. It adapts to your needs,
            providing precise, fast, and insightful support, helping you and
            your team make informed decisions and maintain peak productivity.
            Embrace Ember, and keep your focus on driving success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConnectZoom;

// src/components/Sales.js

import React from "react";
import productOverview from "./../product-overview.png";
import salesOutline from "./../salesOutline.png";
import EmberSales1 from "./../EmberSales1.png";
import salesperson from "./../salesperson.png";

const Sales = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Ember for Sales</h2>
          <div className="image-wrapper">
            <img
              className="usecase-hero-image"
              src={salesperson}
              alt="Sales Overview"
            />
          </div>
        </div>
        <button
          className="usecase-btn"
          onClick={handleButtonClick}
          style={{ margin: "auto", marginTop: "1.5rem" }}
        >
          Start for free
        </button>

        <div className="below-the-fold">
          <div className="usecase-first-block">
            <div>
              <h2>
                Stay fully engaged in your sales conversations without the
                distraction of note-taking
              </h2>

              <ul>
                <li>
                  <b>AI Meeting Notes: </b>Automatically generate accurate
                  transcripts, concise summaries, and clearly defined action
                  items from your meetings.
                </li>
                <li>
                  <b>Coaching and Feedback: </b>Receive tailored feedback on
                  meetings, including enhancements in pitching skills and
                  content delivery.
                </li>
                {/* <li>
                    <b>Collaboration: </b>Facilitate teamwork with one-click
                    sharing options for effortless collaboration.
                  </li> */}
                <li>
                  <b>Analysis and Insights: </b>Leverage
                  histusecase-second-blockorical insights to accelerate deal
                  closures and improve business outcomes.
                </li>
              </ul>

              <button className="get-started-btn" onClick={handleButtonClick}>
                Start for free
              </button>
            </div>
            <div>
              <img src={salesOutline} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="product-overview"
                  src={productOverview}
                  alt="Product Overview"
                />
              </div>
              <h2 className="image-wrapper">
                Easy to share important points and wins with your team
              </h2>
              <p className="image-wrapper">
                Train new team members effectively by sharing best practices in
                one click.
              </p>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button
                    className="get-started-btn"
                    onClick={handleButtonClick}
                  >
                    Get Started
                  </button>
                  <button
                    className="schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="usecase-first-block">
            <div>
              <h2>
                Enhanced analysis features to measure and improve team
                performance over time
              </h2>
              <p>
                <ul>
                  <li>
                    Analyze sales calls to pinpoint areas for improvement or
                    reinforcement.
                  </li>
                  <li>
                    Cut down meeting prep time by having all previous
                    interactions and notes easily retrievable.
                  </li>
                  <li>
                    Conduct thorough retrospectives with Ember's help to
                    understand what worked and what didn’t.
                  </li>
                </ul>
              </p>
              <div>
                <button className="get-started-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
              </div>
            </div>
            <div>
              <img src={EmberSales1} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Improve your sales strategy by utilizing our enhanced analysis
              </h2>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button
                    className="get-started-btn"
                    onClick={handleButtonClick}
                  >
                    Get Started
                  </button>
                  <button
                    className="schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;

// src/components/Release.js

import React, { useState } from "react";
import modeLeft from "./../mode-left.png";
import createTeam from "./../create-team.png";
import longRecording from "./../60min.png";
import emailMe from "./../Email.png";
import outlineMode from "./../outline-mode.png";
import slackChannels from "./../slack-channels.png";
import botFree from "./../botfree.png";
import salesCall from "./../salesCall.png";
import downloadAudio from "./../downloadableaudio.png";
import moreModes from "./../more-modes.png";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ActionItem from "./../ActionItem.png";
import Pitching from "./../Pitching.png";
import EngSpec from "./../EngSpecMode.png";
import PriorityFeedback from "./../PriorityFeedback.png";
import ShareThis from "./../ShareThis.png";
import uploadAudio from "./../UploadAudio.png";
import enhancedTranslation from "./../Translation.png";
import darkMode from "./../DarkMode.png";
import linkSharingv2 from "./../LinkSharingv2.png";
import emberTags from "./../EmberTags.png";

const ReleaseNote = ({ date, title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="release-note-container">
      <div
        className="release-note-header"
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer" }}
      >
        <div className="release-toggle">
          <ArrowForwardIosSharpIcon
            style={{ width: "1rem" }}
            className={expanded ? "rotate-icon" : ""}
          />
        </div>

        <div className="release-note-date">{date}</div>
        <div className="release-note-title">{title}</div>
      </div>

      {expanded && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

const Release = () => {
  const CalendarContent = (
    <>
      <p>
        Ember now offers a streamlined Calendar Integration feature, enabling
        users to join meetings with just a single click. This enhancement
        simplifies your scheduling and meeting access process directly through
        Ember.
      </p>
      <p>Key Feature:</p>
      <ul>
        <li>
          <strong>One-Click Access:</strong> Easily join scheduled meetings from
          your calendar with one click, enhancing convenience and efficiency.
        </li>
      </ul>
      <p>
        This new integration ensures a smoother, more integrated experience,
        allowing you to manage and attend meetings effortlessly.
      </p>
    </>
  );

  const VisitSummary = (
    <>
      <p>
        Ember introduces new functionalities designed to optimize healthcare
        management: Visit Summary and Patient Intake. These features are
        tailored to streamline the organization of patient visits, enhancing
        both preparation and follow-up.
      </p>
      <p>Key Enhancements:</p>
      <ul>
        <li>
          <strong>Visit Summary:</strong> Automatically generate summaries of
          each visit, facilitating better follow-up care and record-keeping.
        </li>
        <li>
          <strong>Patient Intake: </strong> Efficiently organize patient intake
          information, ensuring all necessary data is easily accessible and
          well-organized before each visit.
        </li>
      </ul>
      <p>
        These updates aim to improve the efficiency of healthcare workflows,
        allowing healthcare providers to focus more on patient care and less on
        administrative tasks.
      </p>
    </>
  );

  const AnonymizeContent = (
    <>
      <p>
        Ember is committed to ensuring the confidentiality and security of
        sensitive information. Our latest update introduces the ability to
        anonymize and deidentify progress notes. This feature is designed to
        protect clients' privacy by removing any personally identifiable
        information from documentation.
      </p>
      <p>Key Benefits:</p>
      <ul>
        <li>
          <strong>Increased Confidentiality:</strong> Safeguard patient data by
          anonymizing details that could reveal their identity.
        </li>
        <li>
          <strong>Compliance with Privacy Regulations:</strong> Support
          compliance with privacy laws and regulations by deidentifying
          sensitive information in progress notes.
        </li>
      </ul>
      <p>
        This update enhances the privacy controls within Ember, providing users
        with a secure method to handle and share sensitive documentation
        responsibly.
      </p>
    </>
  );

  const complianceUpdate = (
    <>
      <p>
        We are pleased to announce that Ember has achieved HIPAA and SOC 2
        compliance, affirming our commitment to maintaining high security and
        privacy standards. This compliance demonstrates our dedication to
        safeguarding sensitive information and providing a secure environment
        for our users.
      </p>
      <p>Compliance Achievements:</p>
      <ul>
        <li>
          <strong>HIPAA Compliance:</strong> Ensures the protection of sensitive
          patient health information.
        </li>
        <li>
          <strong>SOC 2 Compliance: </strong> Affirms our adherence to industry
          standards for managing customer data.
        </li>
      </ul>
      <p>
        For more detailed information and to view our compliance status, please
        visit our Trust Center:{" "}
        <a
          href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
          target="_blank"
          rel="noreferrer"
          className="cta-link"
        >
          https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be
        </a>
      </p>
      <p>
        This compliance highlights Ember's ongoing effort to build trust and
        ensure data integrity and security for all our users.
      </p>
    </>
  );

  const slackIntegrationContent = (
    <>
      <img className="create-team" src={slackChannels} alt="Slack Channels" />
      <p>
        Ember now offers Slack Integration, enhancing team communication by
        enabling users to effortlessly share content and updates directly with
        selected channels on Slack. After creating content in Ember, simply
        click the Slack icon to share your insights with your team instantly.
      </p>
      <p>Key Features:</p>
      <ul>
        <li>
          <strong>Seamless Sharing:</strong> Directly share content from Ember
          to Slack with a single click.
        </li>
        <li>
          <strong>Custom Notifications:</strong> Set personalized notifications
          to keep your team informed.
        </li>
        <li>
          <strong>Weekly Reports and Summaries:</strong> Receive summaries of
          your team's weekly achievements, fostering a sense of progress and
          collaboration.
        </li>
      </ul>
      <p>
        This integration is perfect for teams looking to streamline
        communication and stay connected on their accomplishments. Explore Slack
        Integration with Ember for more efficient team interactions.
      </p>
    </>
  );

  const personalizationContent = (
    <>
      <img
        className="select-mode"
        src={outlineMode}
        alt="Select Outline Analysis"
      />
      <p>
        This innovative analysis transforms your content into bullet points,
        making it effortless to scan and organize. Outline Analysis is designed
        for efficiency, allowing users to quickly sort through lists and focus
        on key ideas, streamlining the way content is presented and organized.
      </p>
      <p>Key Features:</p>
      <ul>
        <li>
          <b>Efficient Scanning: </b> Converts text into bullet points for quick
          and easy review.
        </li>
        <li>
          <b>Easy Organization: </b> Arranges content clearly, focusing on key
          concepts for better comprehension.
        </li>
      </ul>
      <p>
        Outline complements our existing features by catering to different user
        needs. For personal note-taking or future reference, Outline is ideal.
        For sharing information with others or when immediate organization is
        necessary, switch to Presenting. Experience the versatility of Ember
        with our new Outline.
      </p>
    </>
  );

  const extendedRecordingDurationContent = (
    <>
      <img
        className="long-recording"
        src={longRecording}
        alt="60min Recording"
      />
      <div>
        <p>
          Ember is excited to announce an enhancement to our recording
          capabilities. Originally limited to 3 minutes, Ember now offers the
          ability to create recordings of up to 60 minutes in length. This
          update opens up new possibilities for various use cases, allowing for
          more in-depth discussions and content creation.
        </p>
        <p>Key Features:</p>
        <ul>
          <li>
            <b>Increased Limit: </b> Record up to 60 minutes per session,
            significantly extending the scope of your conversations.
          </li>
          <li>
            <b>Versatile Use Cases: </b> Ideal for a wider range of activities,
            from detailed discussions to comprehensive content creation.
          </li>
        </ul>
        <p>
          To take advantage of this extended recording duration, please refer to
          the{" "}
          <a
            href="/pricing"
            className="cta-link"
            target="_blank"
            rel="noreferrer"
          >
            details{" "}
          </a>
          of our Team and Sales Plan. Enhance your Ember experience with longer
          recordings.
        </p>
      </div>
    </>
  );

  const enhancedEmailDeliveryContent = (
    <>
      <img
        className="long-recording"
        src={emailMe}
        alt="Instant email delivery"
      />
      <p>
        Ember is pleased to introduce an update that streamlines how you receive
        your recordings. After generating recordings in various analyses, users
        can now select their preferred version and instantly send it to their
        email with just one click. This feature combines speed and convenience,
        ensuring you get your recordings when you need them, without hassle.
      </p>
      <p>Key Advantages:</p>
      <ul>
        <li>
          <b>One-Click Convenience: </b>Choose and send recordings directly to
          your email effortlessly.
        </li>
        <li>
          <b>Speed: </b>Access your selected recordings quickly, enhancing
          productivity.
        </li>
      </ul>
      <p>
        For more information and to access this feature, please explore the Pro
        Plan details. Elevate your Ember experience with our enhanced recording
        delivery service.
      </p>
    </>
  );

  const enhancedTagging = (
    <>
      <img
        className="long-recording"
        src={emberTags}
        alt="Enhanced Tagging and Search Capabilities"
      />
      <p>
        Ember now offers enhanced functionality for organizing and retrieving
        meeting notes. Users can create custom tags for their meeting notes,
        allowing for better categorization and easier access. Additionally, the
        new search capability lets users quickly find specific notes by
        searching for these tags.
      </p>
      <p>Key Features:</p>
      <ul>
        <li>
          <b>Custom Tags: </b>Create personalized tags to organize meeting notes
          effectively.
        </li>
        <li>
          <b>Tag-Based Search: </b>Easily locate specific notes by searching for
          associated tags.
        </li>
      </ul>
      <p>
        This update is designed to streamline the management of meeting notes,
        making it simpler and faster to access the information users need.
      </p>
    </>
  );

  const getWeeklySnippetContent = (
    <>
      <div>
        Weekly Snippet is an innovative tool designed for users who actively
        engage with Ember, making at least three entries from last Friday 12pm
        to the current Friday 12pm. Available every Friday at 1pm, the Weekly
        Snippet provides a comprehensive summary of your most impactful
        activities, achievements, next steps, and wrap-ups over the past week.
        It's an ideal resource for reflection, planning, and streamlining
        information management, particularly for team leaders.
      </div>
      <p>Key Benefits:</p>
      <ul>
        <li>
          <b>Efficiency: </b>
          Save time by easily accessing a consolidated view of the week's key
          outcomes and plans.
        </li>
        <li>
          <b>Customization: </b>
          Team leaders have the flexibility to adjust viewing permissions,
          ensuring that the Weekly Snippet is shared with relevant team members
          only.
        </li>
        <li>
          <b>Engagement: </b>
          Enhance the utility of your Weekly Snippet by regularly logging your
          thoughts, plans, progress, and meeting summaries.
        </li>
      </ul>
      <p>
        To further personalize your Weekly Snippet or integrate it with other
        platforms like email or Slack, please reach out to us at{" "}
        <a className="cta-link" href="mailto:support@embercopilot.ai">
          support@embercopilot.ai
        </a>
        . Make the most of your workweek with Ember's Weekly Snippet!
      </p>
    </>
  );

  const CreateYourGroupContent = (
    <>
      <img className="create-team" src={createTeam} alt="Create Team" />
      <p>
        Elevate team collaboration with Ember Team, our latest feature designed
        to streamline communication and enhance information sharing within
        teams. With a simple click, team members can share recordings directly
        on the team page, fostering easy connectivity and ensuring alignment
        across all team activities. This feature complements and enhances our
        other team-oriented tools, such as the Weekly Snippet.
      </p>
      <p>Benefits of Ember Team:</p>
      <ul>
        <li>
          <b>Efficient Communication: </b>Eliminate the need for lengthy weekly
          reports or multiple emails. Ember Team keeps everyone informed
          effortlessly.
        </li>
        <li>
          <b>Enhanced Collaboration: </b>Access critical information and work
          progress updates without the need to individually contact team
          members.
        </li>
      </ul>
      <p>
        To set up a Ember Team for your team and transform how you collaborate,
        please reach out to us at{" "}
        <a className="cta-link" href="mailto:support@embercopilot.ai">
          support@embercopilot.ai
        </a>
        .
      </p>
    </>
  );

  const customKeywordsContent = (
    <>
      <div>
        Optimize your Ember experience with our Custom Keywords feature,
        tailored to enhance transcription accuracy by incorporating your
        frequently used keywords and acronyms. Whether it's unique names or
        specialized professional phrases, this feature allows you to communicate
        more effortlessly, eliminating the need for constant clarification.
      </div>
      <ul>
        <li>
          <b>Enhanced Accuracy: </b>
          Improve the precision of transcriptions with the addition of personal
          or industry-specific terminology.
        </li>
        <li>
          <b>Effortless Communication: </b>
          Speak freely without the interruption of correcting misunderstood
          words.
        </li>
      </ul>
      <p>
        To add your customized keywords, simply navigate to the settings tab and
        select "Keywords." Unlock seamless communication with Ember's Custom
        Keywords feature.
      </p>{" "}
    </>
  );

  const EnhancedEditingContent = (
    <>
      <div>
        Now, after your content is generated, simply click the edit icon to
        refine your results. This allows you to add a final touch or correct any
        details, ensuring the output perfectly matches your expectations.
      </div>
      <p>Key Enhancements:</p>
      <ul>
        <li>
          <b>Editable Content: </b> Fine-tune and make corrections directly in
          the generated content for precision and personalization.
        </li>
        <li>
          <b>AI Learning: </b> The AI learns from your edits, adapting to better
          match your preferences and tone in future outputs.
        </li>
      </ul>
      <p>
        This feature not only enhances your immediate editing capabilities but
        also personalizes your experience over time, as the AI evolves to align
        more closely with your unique style and requirements.
      </p>
    </>
  );

  const selectModesContent = (
    <>
      <img className="select-mode" src={modeLeft} alt="Select Modes" />
      <p>
        In previous versions, Ember automatically generated content in all four
        AI analyses (Polishing, Concising, Presenting, and Playful) following a
        recording. We've updated this feature to allow you the flexibility to
        choose only the analyses you require, streamlining your workflow by
        omitting those less frequently used. This update ensures a neater and
        more concise interface.
      </p>
      <p>
        AI analyses are designed with specific purposes in mind, enabling you to
        tailor your content based on its nature, your personal preferences, or
        your prior experiences. Alternatively, you can opt to activate all
        analyses if you're unsure which best suits your current needs.
      </p>
      <ul>
        <li>
          <b>Polishing:</b> Enhances your content by improving wording, ensuring
          a smooth flow by removing filler words, repetitions, and grammatical
          errors, essentially providing your script with a professional edit.
        </li>
        <li>
          <b>Presenting:</b> Ideal for preparing presentations or meeting
          outlines, this analysis summarizes and organizes your information.
          It's also beneficial for stimulating new ideas during brainstorming
          sessions.
        </li>
        <li>
          <b>Concise: </b>
          Condenses your recording to its most essential elements, enabling you
          to communicate your message or central idea quickly and effectively.
        </li>
        <li>
          <b>Social: </b>Brings a dynamic tone to your content, ideal for
          capturing your audience's attention on social media, easing into
          conversations, or simply for a more relaxed and engaging sharing
          experience.
        </li>
      </ul>
    </>
  );

  const streamlinedSigninContent = (
    <>
      <div>
        We've upgraded our sign-in process to make accessing our services more
        convenient. Previously, users were required to log in using an email
        address as their username and create a password, which often led to
        frequent password resets.
      </div>
      <p>Now, we're excited to introduce additional sign-in options:</p>
      <ul>
        <li>Google</li>
        <li>Apple</li>
        <li>Email</li>
      </ul>
      <p>
        <p>
          <b>Ease of Access: </b>Streamline your login experience without the
          hassle of remembering another password.
        </p>
        <p>
          <b>Flexible Options: </b>Choose the sign-in method that works best for
          you, enhancing your overall experience with our services.
        </p>
        <p>
          Experience the simplicity of our enhanced sign-in process today and
          stay connected with ease.
        </p>
      </p>
    </>
  );

  const botFreeContent = (
    <>
      <img className="create-team" src={botFree} alt="Bot Free Recording" />
      <p>
        Ember is revolutionizing the way you record meetings, eliminating the
        need for awkward meeting bots. Many AI notetaker users have experienced
        the inconvenience of having bots join their calls for transcription
        purposes, often leading to a less than ideal user experience. We've
        removed this dependency entirely.
      </p>
      <p>Key Advantages:</p>
      <ul>
        <li>
          <b>Universal Compatibility: </b>Our solution works seamlessly across
          all platforms and systems, including both MAC OS and Windows without
          the intrusion of meeting bots.
        </li>
        <li>
          <b>High-Quality Audio Capture: </b>Direct recording from your device
          ensures crisp audio quality on any platform, whether it's Zoom, Google
          Meets, Teams, Discord, or Facetime.
        </li>
        <li>
          <b>Superior Transcription Accuracy: </b>By utilizing the most advanced
          AI models, we guarantee the high-standard transcription and note
          quality you've come to expect from Ember.
        </li>
      </ul>
      <p>
        Experience the convenience of bot-free, high-quality recordings with
        Ember Copilot, your solution for capturing meetings across any platform
        or system.
      </p>
      <p>
        Please note, while Ember streamlines your meeting recording experience,
        we strongly advocate for responsible use of our technology in compliance
        with local laws and regulations. Given the absence of visual cues
        commonly associated with recording, it's crucial to verbally inform all
        participants about the recording beforehand. This practice is not only a
        legal requirement in various U.S. states and EU countries but also
        aligns with our commitment to uphold the highest standards of privacy
        and consent. We recommend obtaining explicit recording consent to ensure
        transparency and respect for all meeting participants' privacy
        preferences.
      </p>
      <p>
        Here are some simple ways to let others know you're using Ember during a
        meeting:
      </p>
      <ul>
        <li>Do you mind I use note-taking tool (in this meeting)?</li>
        <li>Mind if I keep our meeting notes with my note-taking tool?</li>
        <li>
          Hope it's cool if I use a note-taking tool to keep track of everything
          we cover
        </li>
        <li>
          I'm going to use a note-taking tool to capture the key points, if
          that's cool with everyone.
        </li>
        <li>
          I find it super helpful to use a note-taking tool in meetings. I hope
          that's alright with you guys.
        </li>
      </ul>
    </>
  );

  const SalesCallModeContent = (
    <>
      <img
        className="long-recording"
        src={salesCall}
        alt="Sales Call Analysis"
      />
      <p>
        Sales Call and Customer Interview Analysis are two powerful new features
        tailored for business. Designed to enhance productivity and insight
        during crucial interactions, these analyses are equipped to meet the
        specific needs of sales pitches and customer interviews.
      </p>
      <p>
        <b>Sales Call Analysis:</b>
      </p>
      <p>
        Ideal for founders and individuals frequently engaged in pitching and
        selling, this analysis emphasizes:
      </p>
      <ul>
        <li>Strategic focus on systemic impactors and key objections.</li>
        <li>Documentation of responses and handling of objections.</li>
        <li>A retro and improvement process for continual enhancement.</li>
      </ul>
      <p>
        It’s specifically targeted at enhancing the efficiency and effectiveness
        of sales pitches.
      </p>
      <p>
        <b>Customer Interview Analysis:</b>
      </p>
      <p>Designed for in-depth discovery, this analysis highlights:</p>
      <ul>
        <li>Key learnings and observations from user interactions.</li>
        <li>Identification of action items based on user feedback.</li>
      </ul>
      <p>
        This analysis is perfect for gaining valuable insights from customer
        interviews.
      </p>
      <p>Key Features for Both Analyses:</p>
      <ul>
        <li>Actionable insights for immediate application.</li>
        <li>Support for 60+ minute recording durations with the Sales Plan.</li>
      </ul>
      <p>
        To explore these analyses and see how they can benefit your business
        communication,{" "}
        <a
          className="cta-link"
          href="https://calendly.com/embercopilot/30min"
          target="_blank"
          rel="noreferrer"
        >
          schedule a demo
        </a>
        . Enhance your sales and customer engagement strategies with Ember.
      </p>
    </>
  );

  const weeklyReportContent = (
    <>
      <div>
        An update designed to enhance team collaboration: the ability to view
        weekly reports and select sharing analyses directly within the web app.
        This feature streamlines how teams access and disseminate information,
        ensuring everyone is aligned and informed.
      </div>
      <p>
        <b>Key Features:</b>
      </p>
      <ul>
        <li>
          <b>Weekly Reports: </b>Teams can now easily access comprehensive
          weekly reports, summarizing achievements and insights.
        </li>
        <li>
          <b>Selectable Sharing Analyses: </b>Choose how to share these insights
          within the web app, tailoring the distribution to fit your team's
          needs.
        </li>
      </ul>
      <p>
        This update empowers teams to stay connected with their progress and
        effectively share valuable information, all within Ember's user-friendly
        web app.
      </p>
    </>
  );

  const multiLingualContent = (
    <>
      <div>
        Ember offers multi-language support, embracing the diversity of
        languages in your conversations and recordings. You can now freely
        express yourself in multiple languages within a single session, catering
        to both monolingual and multilingual users alike.
      </div>
      <p>
        <b>Key Features:</b>
      </p>
      <ul>
        <li>
          <b>Versatile Language Recognition: </b>Speak in any combination of
          languages you're comfortable with; Ember understands and captures it
          all.
        </li>
        <li>
          <b>Extensive Language Support: </b>Although the default output is in
          English, Ember supports transcription in 45+ languages, ensuring no
          conversation is lost in translation.
        </li>
      </ul>
      <p>
        To tailor your experience further, including customizing the output
        language to meet your specific needs, we invite you to{" "}
        <a
          className="cta-link"
          href="https://calendly.com/embercopilot/30min"
          target="_blank"
          rel="noreferrer"
        >
          book a demo
        </a>
        . Explore the full capabilities of our multi-language support and how it
        can enhance your communication.
      </p>
    </>
  );

  const instantTranscription = (
    <>
      <div>
        Ember offers instant AI transcription for long-duration recordings, such
        as meetings. Within just 30 seconds of your meeting's conclusion, you'll
        have access to both the transcript and concise summary, ready for
        review.
      </div>
      <p>
        <b>Key Features:</b>
      </p>
      <ul>
        <li>
          <b>Rapid Transcription: </b>Receive your meeting's script and
          summaries almost immediately after it ends.
        </li>
        <li>
          <b>Seamless Integration: </b>Perfectly complements our suite of
          features, including bot-free recording environment, Sales Call
          Analysis, and Customer Interview Analysis.
        </li>
        <li>
          <b>Easy Sharing: </b>
          Combined with our streamlined delivery system, sharing insights from
          your meetings with others is effortless.
        </li>
      </ul>
      <p>
        This feature enhances your productivity by providing quick access to
        meeting insights, making it easier than ever to share valuable
        information with team members or stakeholders.
      </p>
    </>
  );

  const downloadAudioContent = (
    <>
      <img
        className="long-recording"
        src={downloadAudio}
        alt="Downloadable Recording Audio"
      />
      <div>
        <p>
          Ember is enhancing the capabilities by allowing users to download the
          audio of their recordings. This feature is ideal for those looking to
          dive deeper into discussions or share recordings with their team,
          making it especially useful for sales retrospectives and customer
          interviews. Previously, Ember offered transcription services
          exclusively, but we recognize the value of having access to the
          original audio for comprehensive analysis and sharing.
        </p>
        <p>Key Benefits:</p>
        <ul>
          <li>
            <b>Versatile Use: </b> Download your recordings for detailed
            exploration or easy sharing with team members.
          </li>
          <li>
            <b>Enhanced Collaboration: </b> Perfect for reviewing sales calls or
            conducting thorough customer interview analyses.
          </li>
        </ul>
        <p>
          For more information on accessing this feature, please visit our{" "}
          <a
            href="/pricing"
            className="cta-link"
            target="_blank"
            rel="noreferrer"
          >
            Sales Plan{" "}
          </a>
          .
        </p>
      </div>
    </>
  );

  const flexModeContent = (
    <>
      <img
        className="long-recording"
        src={moreModes}
        alt="Flexibility with Mode Selection and Regeneration"
      />
      <div>
        <p>
          Ember is rolling out an update that significantly improves the
          flexibility of our AI analysis and regeneration features. Now, users
          have the ability to choose an analysis after creating an entry, a
          change from the previous requirement of selecting a analysis before
          starting a recording. Additionally, if the results from a selected
          analysis don’t meet expectations, users now have the option to
          regenerate an analysis for better outcomes.
        </p>
        <p>Key Updates:</p>
        <ul>
          <li>
            <b>Post-Entry AI Analysis Selection: </b> Choose the most suitable
            analysis for your recording after you've made an entry, allowing for
            more thoughtful consideration of which analysis fits best.
          </li>
          <li>
            <b>AI Analysis Regeneration: </b>
            Easily regenerate an analysis if the initial results aren't
            satisfactory, ensuring your final content aligns with your needs.
          </li>
        </ul>
        <p>
          These updates are designed to make your Ember experience more
          adaptable and user-friendly, giving you greater control over your
          recordings and their outcomes.
        </p>
      </div>
    </>
  );

  const capUpdateContent = (
    <>
      <div>
        <ul>
          <li>
            Improved load times for the recording and team pages, ensuring
            quicker access to content.
          </li>
          <li>
            Enhanced speed in AI analysis generation, making user's experience
            smoother and more efficient.
          </li>
          <li>
            The ability to record in the background without interruptions or
            crashes, offering greater flexibility.
          </li>
          <li>
            Updated sales code analysis and customer interview analysis,
            featuring advanced analysis for deeper insights.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv2 = (
    <>
      <div>
        <ul>
          <li>
            Enhanced the in-app upgrade process to provide users with seamless
            access to extended recording limits when needed.
          </li>
          <li>
            Improved UI responsiveness and visual organization, including text
            alignment and button grouping, for a more intuitive user experience.
          </li>
          <li>
            Streamlined History view to prevent UI freezing issues and declutter
            the interface for ease of navigation.
          </li>
          <li>
            Upgraded email sharing functionality and input field responsiveness
            to improve interaction with the app.
          </li>
          <li>
            Fixed a bug in AI analysis to eliminate the issue of duplicate
            analyses being created.
          </li>
          <li>
            Implemented a robust data backup system to ensure user sessions can
            be recovered without data loss, even in the event of an API issue,
            guaranteeing peace of mind.
          </li>
          <li>
            Made premium features more transparent within the app, allowing
            users to intuitively explore the full set of Ember features,
            informed by user feedback.
          </li>
          <li>
            Streamlined app use with memory of user analysis selections, so
            there's no need to reselect preferences each time. Ember remembers
            users' last choices.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv3 = (
    <>
      <div>
        <ul>
          <li>
            <b>Editable Titles: </b>Titles generated by AI now allow for direct
            user edits.
          </li>
          <li>
            <b>Device Compatibility: </b>Ember has been optimized for full
            compatibility with iPhones, iPads, and Macs.
          </li>
          <li>
            <b>User Experience Optimization: </b>Interface improvements have
            been implemented to streamline navigation and interaction.
          </li>
          <li>
            <b>Bug Fixes: </b>Minor issues have been addressed, including a fix
            for UI loading delays.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv4 = (
    <>
      <div>
        <ul>
          <li>
            <b>Enhanced Search Feature: </b>Ember now allows users to search
            their history by AI analysis type, such as "action items," to
            display all scripts generated with that analysis, simplifying the
            navigation of past entries.
          </li>
          <li>
            <b>Delete Slack Channel Feature: </b>A new feature has been added to
            our web application that enables users to delete Slack channels
            directly, which is especially beneficial for managing multiple
            channels and maintaining a clean Slack integration.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv5 = (
    <>
      <div>
        <ul>
          <li>
            Enhanced Action Items. Ember has improved the suggested action items
            to better align with your scripts, enhancing your workflow
            efficiency.
          </li>
          <li>
            The features "Presenting," "Polishing," and "Concise" are now
            exclusively available for recordings under 5 minutes. This
            adjustment ensures these tools are utilized where most effective.
          </li>
          <li>
            Discontinued Social Mode. To streamline our offerings and focus on
            core functionalities, we have removed the Social Mode feature.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv6 = (
    <>
      <div>
        <ul>
          <li>
            Upgraded voice API to enhance transcription accuracy for meetings.
          </li>
          <li>
            Users can now edit the title and content of their Ask Ember reports,
            previously generated by AI.
          </li>
          <li>
            Added a "Regenerate Transcript" feature to improve transcript
            clarity and accuracy, especially useful in noisy environments.
          </li>
        </ul>
      </div>
    </>
  );

  const capUpdateContentv7 = (
    <>
      <div>
        <ul>
          <li>
            Resolved an issue with token errors in recordings after long,
            multi-participant meetings
          </li>
          <li>
            Introduced a feature displaying remaining minutes to enhance user
            awareness and prevent system misuse.
          </li>
          <li>
            Fixed errors caused by random link insertions with a new alert
            system for invalid link types.
          </li>
          <li>
            Addressed the issue of multiple Zoom bots joining a meeting by
            limiting the entry of a Zoom link to a single instance.
          </li>
        </ul>
      </div>
    </>
  );

  const ActionItemContent = (
    <>
      <img className="long-recording" src={ActionItem} alt="Action Items" />
      <div>
        <p>
          Action Items is a powerful feature that automatically identifies tasks
          from your recordings and organizes them into a prioritized list. This
          analysis automatically detects to-dos from your recordings and
          categorizes them into a concise list, helping you focus on what
          matters most.
        </p>
        <p>Key Features:</p>
        <ul>
          <li>
            <b>Task Detection: </b>
            Automatically identifies actionable items from your spoken content.
          </li>
          <li>
            <b>Priority Categorization: </b>Organizes tasks into categories,
            such as top priority, next steps, and low priority for efficient
            planning.
          </li>
        </ul>
        <p>
          Action Items is designed to streamline your workflow by transforming
          your discussions into organized, prioritized actions. For more
          information on accessing this feature, please visit our{" "}
          <a
            href="/pricing"
            className="cta-link"
            target="_blank"
            rel="noreferrer"
          >
            Team Plan{" "}
          </a>
          .
        </p>
      </div>
    </>
  );

  const PitchingContent = (
    <>
      <img className="long-recording" src={Pitching} alt="Pitching Analysis" />
      <div>
        <p>
          A new feature designed to refine your pitching skills. This analysis
          evaluates your pitch on various critical aspects, including clarity,
          pace, confidence, use of filler words, and overall tone. Based on this
          analysis, it provides constructive feedback and actionable suggestions
          to enhance your next pitch.
        </p>
        <p>Key Benefits:</p>
        <ul>
          <li>
            <b>Comprehensive Feedback: </b>Receive detailed insights on how to
            improve clarity, pace, and confidence, while reducing hedging and
            filler words.
          </li>
          <li>
            <b>Actionable Improvement:</b>Gain specific, actionable advice
            tailored to make your future pitches more effective.
          </li>
        </ul>
        <p>
          Pitching Analysis is ideal for anyone looking to elevate their
          presentation skills and make a lasting impression with every pitch.
          For more information on accessing this feature, please visit our{" "}
          <a
            href="/pricing"
            className="cta-link"
            target="_blank"
            rel="noreferrer"
          >
            Sales Plan{" "}
          </a>
          .
        </p>
      </div>
    </>
  );

  const EngSpecContent = (
    <>
      <img
        className="long-recording"
        src={EngSpec}
        alt="Eng Spec
"
      />
      <div>
        <p>
          Introducing Eng Spec, a transformative feature designed for engineers
          and managers. This analysis allows you to simply speak to Ember about
          the features and requirements you envision. Ember will then convert
          these discussions into a structured spec document and will be ready to
          share on Confluence.
        </p>
        <p>Key Features:</p>
        <ul>
          <li>
            <b>Simplified Creation: </b>Talk through your project requirements
            and feature ideas directly with Ember, and watch as they're
            automatically transformed into a comprehensive spec document.
          </li>
        </ul>
        <p>
          Eng Spec is tailor-made to streamline the process of documenting
          technical specifications, making it easier than ever to communicate
          and share your vision with your team on Confluence.
        </p>
      </div>
    </>
  );

  const priorityFeedback = (
    <>
      <img
        className="create-team"
        src={PriorityFeedback}
        alt="Priority Feedback"
      />
      <p>
        Ember now offers Priority Support through our innovative Feedback
        Method, enhancing how you communicate your needs directly to our team.
        This feature allows you to voice any feedback, from bug reports and
        feature requests to ideas and suggestions, just as you would record a
        normal conversation in Ember. Easily accessible through the "Account"
        section and trackable in your history, Feedback Method ensures that your
        input is quickly and efficiently addressed.
      </p>
      <p>Key Features:</p>
      <ul>
        <li>
          <b>Direct Communication: </b>Speak your feedback directly into Ember,
          bypassing the need for typing out requests.
        </li>
        <li>
          <b>Quick Access and Tracking: </b>Locate Feedback Method in your
          account settings and review past submissions in your history.
        </li>
      </ul>
      <p>
        This streamlined approach allows the Ember team to understand and
        respond to your needs more effectively, continually improving your user
        experience.
      </p>
    </>
  );

  const shareThisContent = (
    <>
      <img className="long-recording" src={ShareThis} alt="Link Sharing" />
      <div>
        <p>
          Ember now enables users to share their scripts and analysis with
          non-Ember users via a simple link. This new feature enhances
          collaboration by allowing you to provide access to detailed
          transcripts and insights, even to those outside of the Ember.
        </p>
        <p>Key Benefit:</p>
        <ul>
          <li>
            <b>Expanded Accessibility: </b>Easily share a link to allow anyone,
            regardless of whether they use Ember, to view the full script and
            analysis.
          </li>
        </ul>
        <p>
          This update is perfect for broadening the reach of users' insights,
          ensuring that all relevant parties can access the information they
          need, seamlessly and efficiently.
        </p>
      </div>
    </>
  );

  const uploadAudioContent = (
    <>
      <img
        className="long-recording"
        src={uploadAudio}
        alt="Upload Audio for Offline Recordings"
      />
      <div>
        <p>
          Ember introduces a new feature that enhances flexibility for our
          users. Now, users can upload recordings including WEBM, MP3, MP4, WAV,
          and M4A. directly to Ember to generate scripts and comprehensive
          analyses. This is especially useful in environments with poor internet
          connectivity, where users can record offline and upload later.
          Additionally, this feature allows users to analyze any previous
          recordings at their convenience.
        </p>
        <p>Key Benefits:</p>
        <ul>
          <li>
            <b>Enhanced Accessibility: </b>Upload recordings anytime, bypassing
            the need for a live internet connection.
          </li>
          <li>
            <b>Flexible Analysis: </b>Review and analyze past recordings
            whenever needed, ensuring you never miss out on valuable insights.
          </li>
        </ul>
      </div>
    </>
  );

  const enhancedTranslationContent = (
    <>
      <img
        className="long-recording"
        src={enhancedTranslation}
        alt="Enhanced Multi-Language Support"
      />
      <div>
        <p>
          Ember proudly extends its multi-language capabilities, supporting the
          diverse linguistic needs of our users. Whether your conversations and
          meetings are in one language or multiple, Ember can seamlessly capture
          the dialogue. Users also have the flexibility to select their
          preferred language for the output.
        </p>
        <p>Key Features:</p>
        <ul>
          <li>
            <b>Broad Language Coverage: </b>Supports recording in over 45
            languages, catering to a global user base.
          </li>
          <li>
            <b>Flexible Output Options: </b>Currently, transcripts can be
            provided in English, Spanish, Chinese, Japanese, and Korean, with
            additional languages available upon request.
          </li>
        </ul>
        <p>
          This enhancement is ideal for both monolingual and multilingual users,
          ensuring that everyone can effortlessly capture and review
          conversations in their language of choice.
        </p>
      </div>
    </>
  );

  const darkModeContent = (
    <>
      <img className="create-team" src={darkMode} alt="Dark Mode" />
      <p>
        Ember now offers Dark Mode, enhancing visual comfort and accessibility
        for all users. This feature provides an alternative, eye-friendly
        interface that reduces screen glare and is ideal for low-light
        environments, allowing for longer, more comfortable usage periods.
      </p>
    </>
  );

  const refinedLinkSharingContent = (
    <>
      <img
        className="long-recording"
        src={linkSharingv2}
        alt="Refined Link Sharing Features"
      />
      <div>
        <p>
          Ember has upgraded its link sharing capabilities, offering greater
          control over what you share. Previously, sharing a link included the
          entire transcript, analysis, and action items. Now, users can select
          specific analyses to share via link, tailoring the content visible to
          recipients who are not Ember users.
        </p>
        <p>
          Additionally, you can now delete any unnecessary scripts or analyses
          from the shared content, ensuring that recipients receive only the
          most relevant information.
        </p>
        <p>Key Features:</p>
        <ul>
          <li>
            <b>Content Control: </b>Easily remove unwanted scripts or analyses
            from the link, streamlining the information shared.
          </li>
        </ul>
      </div>
    </>
  );

  const interviewAnalysisContent = (
    <>
      {/* <img
        className="long-recording"
        src={linkSharingv2}
        alt="Refined Link Sharing Features"
      /> */}
      <div>
        <p>
          Interview Analysis is designed to provide comprehensive insights into
          candidate interviews. This new feature helps you analyze interview
          transcripts meticulously to gauge the candidate's honesty, strengths,
          weaknesses, communication style, motivation, and interest in the role.
        </p>
        <p>
          <b>Feature Highlights:</b>
        </p>
        <ul>
          <li>
            <b>Honesty and Consistency: </b>Detect shifts in tone and
            inconsistencies that may suggest dishonesty.
          </li>
          <li>
            <b>Key Strengths and Areas for Improvement: </b>Identify the
            candidate’s core competencies and areas where development is needed
            based on their responses.
          </li>
          <li>
            <b>Communication Skills and Style: </b>Evaluate how the candidate
            interacts and which types of team dynamics they may thrive in or
            find challenging.
          </li>
          <li>
            <b>Motivation and Growth Mindset: </b>Assess the candidate’s
            enthusiasm for learning and their reaction to feedback.
          </li>
          <li>
            <b>Interest in the Role: </b>Determine areas of strong interest and
            potential lack of engagement.
          </li>
        </ul>
        <p>
          Ember's Interview Analysis encourages detailed, example-backed
          evaluations to support your hiring decisions effectively.
        </p>
      </div>
    </>
  );

  const therapyProgressNoteContent = (
    <>
      <div>
        <p>
          This feature is specially designed for therapy professionals. It
          simplifies documentation by automatically generating SOAP Notes
          alongside the original transcripts, significantly reducing the time
          spent on note-taking post-therapy sessions.
        </p>
        <p>
          The "Therapy Progress Note" ensures therapists can efficiently
          document essential details of their sessions, allowing them to focus
          more on client care and less on administrative tasks. Embrace a new
          level of efficiency in therapy session documentation with Ember.
        </p>
      </div>
    </>
  );

  const meetingBotContent = (
    <>
      <div>
        <iframe
          style={{ borderRadius: "1rem" }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/36Bw2LEhl3k?si=oZwWMlDmrUfUxXZM"
          title="AI Copilot for Google Meet"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p>
          Ember introduces a seamless enhancement to your Zoom, Google Meet, and
          Microsoft Teams meetings with our Meeting Bot. This feature is
          designed to streamline the recording process, ensuring clarity and
          transparency during your sessions. All participants are informed when
          recording is active, fostering a trustworthy environment and
          alleviating concerns about whether the meeting is being captured.
        </p>
        <p>
          <b>Key Benefits:</b>
        </p>
        <ul>
          <li>
            <b>Smooth Recording Process: </b>
            Automates and simplifies recording, making it effortless to capture
            every part of your meeting.
          </li>
          <li>
            <b>Transparency: </b>
            Clearly notifies all participants when recording starts, providing
            reassurance and building trust within the team.
          </li>
        </ul>
        <p>
          Enhance your meeting efficiency and reliability with Ember's Zoom and
          Google Meet Bot, designed to support your collaboration needs
          effectively.
        </p>
      </div>
    </>
  );

  const audioPlaybackContent = (
    <>
      <div>
        <p>
          In addition to obtaining scripts and downloading audio files, users
          can now listen to recordings directly within Ember. This update
          includes the ability to adjust playback speed and share recordings
          with others easily.
        </p>
        <p>
          <b>Key Features:</b>
        </p>
        <ul>
          <li>
            <b>In-App Audio Playback: </b>
            Listen to your recordings directly on the Ember.
          </li>
          <li>
            <b>Adjustable Playback Speed: </b>
            Control the speed of audio playback to suit your listening
            preferences.
          </li>
          <li>
            <b>Easy Sharing: </b>
            Effortlessly share recordings with colleagues or friends directly
            from the app.
          </li>
        </ul>
        <p>
          These new functionalities enhance the usability of Ember, making it
          even more convenient for users to review and share their audio content
          effectively.
        </p>
      </div>
    </>
  );

  const speakerDiarizationContent = (
    <>
      <div>
        <p>
          Speaker Diarization feature let Ember accurately identify and
          differentiate speakers in the meetings. This feature enhances the
          precision of interview analyses, pitching suggestions, and the
          generation of action items by accurately attributing dialogue to
          specific speakers.
        </p>
        <p>
          <b>Key Benefits:</b>
        </p>
        <ul>
          <li>
            <b>Accurate Speaker Identification: </b>
            Clearly distinguishes who is speaking at any given time, enhancing
            the clarity of transcriptions.
          </li>
          <li>
            <b>Enhanced Analysis Accuracy: </b>
            Improves the accuracy of interview analyses, pitching feedback, and
            action items by relying on direct speaker identification rather than
            model inference.
          </li>
        </ul>
        <p>
          Speaker Diarization feature is essential for users needing detailed
          and accurate breakdowns of conversations.
        </p>
      </div>
    </>
  );

  const videoPlaybackContent = (
    <>
      <div>
        <p>
          Users can playback and download videos of their meetings directly from
          Ember.
        </p>
        <p>
          <b>Key Features:</b>
        </p>
        <ul>
          <li>
            <b>Video Playback: </b>
            Conveniently view your meeting videos within Ember.
          </li>
          <li>
            <b>Download Option: </b>
            Easily download videos for offline viewing and record-keeping.
          </li>
        </ul>
        <p>
          This addition is designed to provide users with greater flexibility in
          accessing and managing their meeting content, ensuring they can review
          and utilize video recordings as needed.
        </p>
      </div>
    </>
  );

  const askEmberAIContent = (
    <>
      <div className="video-container">
        <iframe
          style={{ borderRadius: "1rem" }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7aEi0-AkkeA?si=i5roFxNt3_ipcLDA"
          title="Unlock Business Insights"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div>
        <p>
          Ask Ember, our new AI-driven feature that allows users to delve into
          past meetings for insights and analyses. With Ember Copilot, you can
          ask specific questions and receive detailed answers, ensuring you
          extract maximum value from your recorded sessions.
        </p>
        <p>
          <b>Key Features:</b>
        </p>
        <ul>
          <li>
            <b>Interactive Queries: </b>
            Pose questions directly to Ember Copilot and receive targeted
            insights and analyses from your past meetings.
          </li>
          <li>
            <b>Choice of Advanced Models: </b>
            Select from several cutting-edge AI models, including
            gpt-3.5-turbo-0125, gpt-4-turbo-preview, gpt-4o, and
            gpt-4o-2024-05-13, to tailor the analysis to your needs.
          </li>
          <li>
            <b>Customizable Date Range: </b>
            Filter your search by specifying date ranges, focusing the analysis
            on relevant meeting periods.
          </li>
        </ul>
        <p>
          Ask Ember enhances your ability to review and analyze meeting content
          effectively, providing users with the tools to make informed decisions
          based on past discussions.
        </p>
      </div>
    </>
  );

  const confidentialModeContent = (
    <>
      <div>
        <p>
          Confidential Mode is a new feature designed to enhance privacy and
          build trust, especially for therapists and their patients. This mode
          ensures that all communications are handled with the highest level of
          confidentiality.
        </p>
        <p>
          <b>Key Benefits:</b>
        </p>
        <ul>
          <li>
            <b>Enhanced Privacy: </b>
            Securely manage and store sensitive information, ensuring it remains
            private.
          </li>
          <li>
            <b>Increased Trust: </b>
            Foster a secure environment where clients and patients can feel safe
            sharing personal information.
          </li>
        </ul>
        <p>
          Confidential Mode is committed to upholding the highest standards of
          privacy and trust, making it an ideal choice for professionals
          requiring discretion.
        </p>
      </div>
    </>
  );

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>What's New in Ember Copilot?</h1>
        <ReleaseNote
          date="7/15/2024"
          title="Enhanced Features for Healthcare: Visit Summary and Patient Intake"
        >
          {VisitSummary}
        </ReleaseNote>
        <ReleaseNote
          date="7/8/2024"
          title="Calendar Integration: One-Click Meeting Access"
        >
          {CalendarContent}
        </ReleaseNote>
        <ReleaseNote
          date="6/20/2024"
          title="Enhanced Privacy Features: Anonymize and Deidentify Progress Notes"
        >
          {AnonymizeContent}
        </ReleaseNote>
        <ReleaseNote
          date="6/3/2024"
          title="Compliance Updates: HIPAA and SOC 2"
        >
          {complianceUpdate}
        </ReleaseNote>
        <ReleaseNote date="5/24/2024" title="Confidential Mode">
          {confidentialModeContent}
        </ReleaseNote>
        <ReleaseNote date="5/22/2024" title="Capabilities Update">
          {capUpdateContentv7}
        </ReleaseNote>
        <ReleaseNote
          date="5/20/2024"
          title="Enhanced Tagging and Search Capabilities"
        >
          {enhancedTagging}
        </ReleaseNote>
        <ReleaseNote date="5/16/2024" title="Capability Updates">
          {capUpdateContentv6}
        </ReleaseNote>
        <ReleaseNote date="5/13/2024" title="Ask Ember Copilot">
          {askEmberAIContent}
        </ReleaseNote>
        <ReleaseNote date="5/12/2024" title="Capability Updates">
          {capUpdateContentv5}
        </ReleaseNote>
        <ReleaseNote date="5/10/2024" title="Video Playback and Download">
          {videoPlaybackContent}
        </ReleaseNote>
        <ReleaseNote date="5/9/2024" title="Speaker Diarization">
          {speakerDiarizationContent}
        </ReleaseNote>
        <ReleaseNote date="5/8/2024" title="Audio Playback">
          {audioPlaybackContent}
        </ReleaseNote>
        <ReleaseNote date="5/6/2024" title="Capability Updates">
          {capUpdateContentv4}
        </ReleaseNote>
        <ReleaseNote date="5/3/2024" title="Therapy Progress Note">
          {therapyProgressNoteContent}
        </ReleaseNote>
        <ReleaseNote date="5/2/2024" title="Interview Analysis">
          {interviewAnalysisContent}
        </ReleaseNote>
        <ReleaseNote date="4/30/2024" title="Refined Link Sharing Features">
          {refinedLinkSharingContent}
        </ReleaseNote>
        <ReleaseNote date="4/29/2024" title="Dark Mode">
          {darkModeContent}
        </ReleaseNote>
        <ReleaseNote date="4/26/2024" title="Enhanced Multi-Language Support">
          {enhancedTranslationContent}
        </ReleaseNote>
        <ReleaseNote
          date="4/24/2024"
          title="Meeting Bot for Zoom, Google Meet and Microsoft Teams Recordings"
        >
          {meetingBotContent}
        </ReleaseNote>
        <ReleaseNote date="4/23/2024" title="Link Sharing">
          {shareThisContent}
        </ReleaseNote>
        <ReleaseNote
          date="4/21/2024"
          title="Upload Audio for Offline Recordings"
        >
          {uploadAudioContent}
        </ReleaseNote>
        <ReleaseNote date="4/19/2024" title="Capability Updates">
          {capUpdateContentv3}
        </ReleaseNote>
        <ReleaseNote date="4/17/2024" title="Priority Support with Feedback">
          {priorityFeedback}
        </ReleaseNote>
        <ReleaseNote date="4/15/2024" title="Capability Updates">
          {capUpdateContentv2}
        </ReleaseNote>
        <ReleaseNote date="4/12/2024" title="Eng Spec">
          {EngSpecContent}
        </ReleaseNote>
        <ReleaseNote date="4/10/2024" title="Action Items">
          {ActionItemContent}
        </ReleaseNote>
        <ReleaseNote date="4/9/2024" title="Pitching Analysis">
          {PitchingContent}
        </ReleaseNote>
        <ReleaseNote
          date="4/8/2024"
          title="Flexibility with AI Analysis and Regeneration"
        >
          {flexModeContent}
        </ReleaseNote>
        <ReleaseNote date="4/5/2024" title="Capability Updates">
          {capUpdateContent}
        </ReleaseNote>
        <ReleaseNote date="4/4/2024" title="Downloadable Recording Audio">
          {downloadAudioContent}
        </ReleaseNote>
        <ReleaseNote
          date="4/3/2024"
          title="Instant Transcription for Long-Duration Recordings"
        >
          {instantTranscription}
        </ReleaseNote>
        <ReleaseNote date="4/2/2024" title="Multi-Language Support">
          {multiLingualContent}
        </ReleaseNote>
        <ReleaseNote
          date="4/1/2024"
          title="Bot-Free Recording Across All Platforms
"
        >
          {botFreeContent}
        </ReleaseNote>
        <ReleaseNote
          date="3/29/2024"
          title="Weekly Reports and Sharing AI Analysis for Teams"
        >
          {weeklyReportContent}
        </ReleaseNote>
        <ReleaseNote
          date="3/28/2024"
          title="Sales Call and Customer Interview Analysis"
        >
          {SalesCallModeContent}
        </ReleaseNote>
        <ReleaseNote date="3/27/2024" title="Slack Integration">
          {slackIntegrationContent}
        </ReleaseNote>
        <ReleaseNote date="3/26/2024" title="Introducing Outline Mode">
          {personalizationContent}
        </ReleaseNote>
        <ReleaseNote date="3/25/2024" title="Extended Recording Duration">
          {extendedRecordingDurationContent}
        </ReleaseNote>
        <ReleaseNote date="3/23/2024" title="Enhanced Email Delivery">
          {enhancedEmailDeliveryContent}
        </ReleaseNote>
        <ReleaseNote date="3/19/2024" title="Get your Weekly Snippet">
          {getWeeklySnippetContent}
        </ReleaseNote>
        <ReleaseNote date="3/16/2024" title="Create Your Group">
          {CreateYourGroupContent}
        </ReleaseNote>
        <ReleaseNote date="3/14/2024" title="Custom Keywords">
          {customKeywordsContent}
        </ReleaseNote>
        <ReleaseNote
          date="3/11/2024"
          title="Enhanced Editing and Personalization"
        >
          {EnhancedEditingContent}
        </ReleaseNote>
        <ReleaseNote date="3/10/2024" title="Select the AI Analysis You Need">
          {selectModesContent}
        </ReleaseNote>
        <ReleaseNote
          date="3/8/2024"
          title="Streamlined Sign-In Process Now Available"
        >
          {streamlinedSigninContent}
        </ReleaseNote>
      </div>
    </div>
  );
};

export default Release;

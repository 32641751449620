// src/components/Healthcare.js

import React from "react";
import execs from "./../execs.png";
import execsTop from "./../ExecsTop.png";
import execsMiddle from "./../ExecsMiddle.png";

const Executives = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Ember for Executives</h2>
        </div>
        <div className="image-wrapper">
          <img
            className="usecase-hero-image"
            src={execs}
            alt="Executives Overview"
          />
        </div>
        <button
          className="usecase-btn"
          onClick={handleButtonClick}
          style={{ margin: "auto", marginTop: "1.5rem" }}
        >
          Start for free
        </button>
        <div className="below-the-fold">
          <div className="execs-first-block">
            <div>
              <h2>Focus on the discussion and let Ember take notes for you</h2>

              <ul>
                <li>
                  Use Ember to quickly identify and document key decisions and
                  action items from meetings, ensuring no detail is overlooked.
                </li>
                <li>
                  Effortlessly share meeting highlights and critical insights
                  with your team or stakeholders.
                </li>
                <li>
                  Utilize Ember's advanced analysis to address and track
                  strategic objectives.
                </li>
              </ul>

              <button
                className="usecase-btn"
                onClick={handleButtonClick}
                style={{ marginLeft: "1.5rem" }}
              >
                Start for free
              </button>
            </div>
            <div>
              <img src={execsTop} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="product-overview"
                  src={execsMiddle}
                  alt="Healthcare Overview"
                />
              </div>
              <h2 className="image-wrapper">Effective Management</h2>
              <p className="image-wrapper">
                <ul>
                  <li>
                    Monitor follow-ups and outcomes from meetings with Ember,
                    enhancing your ability to manage projects and timelines
                    efficiently.
                  </li>
                  <li>
                    Create a searchable archive of all executive meetings with
                    Ember, making it simple to retrieve information whenever
                    necessary.
                  </li>
                  <li>
                    Improve collaboration and decision-making across teams by
                    providing access to summarized and analyzed meeting content.
                  </li>
                </ul>
              </p>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="usecase-first-block">
            <div>
              <h2>Patient Privacy and Data Security</h2>
              <p>
                <ul>
                  <li>
                    Ensure the confidentiality and security of patient
                    information.
                  </li>
                  <li>
                    Adheres to HIPAA and SOC 2 standards, ensuring top-level
                    data protection.
                  </li>
                  <li>
                    Enhance privacy with options to operate in a confidential
                    mode.
                  </li>
                  <li>
                    Improve transcription accuracy without the need for training
                    on your data.
                  </li>
                </ul>
              </p>
              <div>
                <button className="usecase-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
              </div>
            </div>
            <div>
              <img
                src={confidentialMode}
                alt="Fast and Accurate AI Note-taking"
              />
            </div>
          </div> */}
          <div className="usecase-second-block" style={{ marginTop: "4rem" }}>
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Ensure every critical decision and action item is captured
                accurately
              </h2>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Executives;

// src/components/Research.js

import React from "react";
import classSummary from "./../ClassSummary.png";
import researchOverview from "./../researchOverview.png";
import classBullets from "./../classBullets.png";
import researcher from "./../researcher.png";

const Research = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Ember for Research</h2>
        </div>
        <div className="image-wrapper">
          <img
            className="usecase-hero-image"
            src={researcher}
            alt="Education and Research Overview"
          />
        </div>
        <button
          className="usecase-btn"
          onClick={handleButtonClick}
          style={{ margin: "auto", marginTop: "1.5rem" }}
        >
          Start for free
        </button>

        <div className="below-the-fold">
          <div className="usecase-first-block">
            <div>
              <h2>
                Focus and be active on learning, discussing, and critical
                thinking.
              </h2>
              <ul>
                <li>
                  <b>AI Meeting Notes: </b>Automatically generate accurate
                  transcripts, concise summaries, and clearly defined action
                  items from your meetings and discussions.
                </li>
                <li>
                  <b>Speed and Accuracy: </b>Save your time with the fast
                  processing and high accuracy in transcriptions.
                </li>
                <li>
                  <b>Analysis and Insights: </b>Effortlessly search historical
                  data for needed information and gain valuable insights from
                  past interactions.
                </li>
              </ul>

              <button
                className="usecase-btn"
                onClick={handleButtonClick}
                style={{ marginLeft: "1.5rem" }}
              >
                Start for free
              </button>
            </div>
            <div>
              <img src={classSummary} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="product-overview"
                  src={researchOverview}
                  alt="Research Overview"
                />
              </div>
              <h2 className="image-wrapper">Advanced Search and Analysis</h2>
              <p className="image-wrapper">
                <ul>
                  <li>Effortlessly search within a specific time range.</li>
                  <li>
                    Gain insights to refine your research questions or
                    educational focus based on past interactions.
                  </li>
                  <li>
                    Enhance teamwork with one-click sharing for easy
                    collaboration.
                  </li>
                </ul>
              </p>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="usecase-first-block">
            <div>
              <h2>
                Concentrate on the discussion while Ember handles the
                note-taking and summarizing.
              </h2>
              <p>
                <ul>
                  <li>
                    Keep all your meetings and discussions in one place for easy
                    future access and reference.
                  </li>
                  <li>
                    Automatically generate summaries of your educational
                    sessions to facilitate quick reviews.
                  </li>
                  <li>
                    Enhance all your academic preparation, including exam
                    preparation, paper writing, and presentation skills with
                    organized notes and transcripts from study sessions.
                  </li>
                </ul>
              </p>
              <div>
                <button className="usecase-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
              </div>
            </div>
            <div>
              <img src={classBullets} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Enhance your focus on learning and critical thinking
              </h2>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;

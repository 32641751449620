import React from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DataPrivacyPolicy from "./components/DataPrivacyPolicy"; // Adjust the import path as needed
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Product from "./components/Product";
import Sales from "./components/Sales";
import Healthcare from "./components/Healthcare";
import Business from "./components/Business";
import Execs from "./components/Executives";
import Research from "./components/Research";
import Marketing from "./components/Marketing";
import Remote from "./components/Remote";
import Terms from "./components/Terms";
import Responsible from "./components/Responsible";
import Release from "./components/Release";
import HelpCenter from "./components/HelpCenter";
import FAQ from "./components/FAQ";
import ContactUs from "./components/ContactUs";
import ConnectSlack from "./components/ConnectSlack";
import ConnectZoom from "./components/ConnectZoom";
import ZoomQuickstart from "./components/ZoomQuickstart";
import Pricing from "./components/Pricing";
import Gift from "./components/Gift";
import BottomNav from "./components/BottomNav";
import Demo from "./components/Demo";
import ReferralLanding from "./components/ReferralLanding";
import "./App.css";

function MainComponent() {
  Intercom({
    app_id: "eh1gr9yc",
  });

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Healthcare />} />
        <Route path="/home" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/business" element={<Business />} />
        <Route path="/healthcare" element={<Healthcare />} />
        <Route path="/executives" element={<Execs />} />
        <Route path="/research" element={<Research />} />
        <Route path="/connect-slack" element={<ConnectSlack />} />
        <Route path="/connect-zoom" element={<ConnectZoom />} />
        <Route path="/zoom" element={<ZoomQuickstart />} />
        <Route path="/changelog" element={<Release />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/remote" element={<Remote />} />
        <Route path="/support" element={<HelpCenter />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy" element={<DataPrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/responsible" element={<Responsible />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/gift" element={<Gift />} />
        <Route path="*" element={<Home />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/refer" element={<ReferralLanding />} />
        {/* Add other routes as needed */}
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default MainComponent;

// src/components/Healthcare.js

import React from "react";
import marketing from "./../marketingOverview.png";
import askEmberAI from "./../AskEmberAI.png";
import marketingOverview from "./../MarketingTop.png";

const Healthcare = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div style={{ textAlign: "center" }}>
          <h2>Marketing Research and User Interviews</h2>
        </div>
        <div className="image-wrapper">
          <img
            className="usecase-hero-image"
            src={marketing}
            alt="Marketing Research and User Interviews"
          />
        </div>
        <button
          className="usecase-btn"
          onClick={handleButtonClick}
          style={{ margin: "auto", marginTop: "1.5rem" }}
        >
          Start for free
        </button>
        <div className="below-the-fold">
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="product-overview"
                  src={marketingOverview}
                  alt="Marketing Research and User Interviews"
                />
              </div>
              <h2 className="image-wrapper">
                Stay focused on the discussions without distraction
              </h2>
              <p className="image-wrapper">
                <ul>
                  <li>
                    Generate concise summaries of user interviews to facilitate
                    quick decision-making and reporting.
                  </li>
                  <li>
                    Cut down on prep and follow-up by having all your research
                    notes automatically organized.
                  </li>
                </ul>
              </p>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="usecase-first-block">
            <div>
              <h2>Advanced Analysis with Ask Ember</h2>
              <p>
                <ul>
                  <li>
                    Leverage Ember's analytics to identify patterns and trends
                    across different user interactions.
                  </li>
                  <li>
                    Extract key insights and themes quickly from interviews to
                    understand customer needs better.
                  </li>
                  <li>
                    Highlight customer pain points and satisfaction drivers,
                    enhancing product development and marketing strategies.
                  </li>
                  <li>
                    Track how consumer opinions change over time by comparing
                    past and current interview data with Ember’s help.
                  </li>
                </ul>
              </p>
              <div>
                <button className="usecase-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
              </div>
            </div>
            <div>
              <img src={askEmberAI} alt="Fast and Accurate AI Note-taking" />
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Track how consumer opinions evolve over time with Ember’s help
              </h2>
              <div className="cta-btn-container">
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                  <button
                    className="usecase-schedule-demo-btn"
                    onClick={handleScheduleClick}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Healthcare;

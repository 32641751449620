// src/components/Business.js

import React, { useState } from "react";
import productOverview from "./../product-overview.png";
import healthie from "./../healthie-logo.png";
// import maine from "./../mainemed-logo.png";
import ntuh from "./../ntuh-logo.png";
import dtf from "./../DinTaiFung-logo.png";
import hante from "./../hantepay-logo.png";
import sunmi from "./../sunmi-logo.png";
import hipaaOffice from "./../hipaa-official.png";

const Business = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };

  const [isAnnual, setIsAnnual] = useState(false);

  const timeSavingsData = [
    {
      role: "Transcribe",
      stat: "33%",
      description:
        "Ember quickly turns your meeting's spoken words into written text. It can summarize conversations, convert them into different formats, and even translate them into various languages, making everything easy to understand for everyone.",
      subtext: "Reduce time spent approx.",
    },
    // {
    //   role: "Family Doctors",
    //   stat: "50%",
    //   description: "of workday currently consumed by documentation tasks.",
    //   subtext: "Cut back on up to",
    // },
    {
      role: "Save/Share",
      stat: "2/3",
      description:
        "All your notes and summaries are stored neatly in one place with Ember. If you need to share these with your team, just a single click will do the trick, saving you time and hassle.",
      subtext: "Reduce",
    },
    {
      role: "Analysis",
      stat: "30%",
      description:
        "With Ember, you can easily explore deeper insights from your meetings. Just ask Ember, and it will analyze your notes to pull out important information and helpful summaries, helping you make better decisions.",
      subtext: "Minimize the time at least",
    },
  ];

  const plans = [
    {
      name: "Free",
      price: "$0",
      description: "Experience Ember for Free",
      features: ["3 Meetings", "Cancel Anytime"],
      btncss: "basic",
      annualPrice: "$0",
      buttonLink: "https://app.embercopilot.ai/",
      annualPlanLink: "https://app.embercopilot.ai/",
      buttonLabel: "Try for Free",
      buttonpromo: "No Credit Card Required",
    },
    {
      name: "Remember",
      price: "$49.99 USD",
      unit: "per user/month",
      annualUnit: "per user/month, billed annually",
      description: "Simplify Your Meeting Management",
      features: ["Unlimited Meetings", "Cancel Anytime"],
      btncss: "basic",
      buttonLink: "https://app.embercopilot.ai/",
      buttonpromo: "No Credit Card Required",
      buttonLabel: "Try for Free",
      annualPrice: "$40.99 USD",
      annualPlanText: "Annual Payment Discount",
      annualPlanLink: "https://app.embercopilot.ai/",
    },
    {
      name: "Enterprise",
      price: "",
      unit: "",
      description: "Optimize Your Organization's Efficiency",
      features: [
        "License Management",
        "Enterprise-Level Security",
        "Dedicated Support",
      ],
      btncss: "business",
      buttonLink: "https://calendly.com/embercopilot/30min",
      buttonLabel: "Contact Us",
      annualPrice: "",
      annualPlanText: "Contact Us",
      annualPlanLink: "https://calendly.com/embercopilot/30min",
    },
  ];

  // Modify plans based on isAnnual state
  const modifiedPlans = plans.map((plan) => ({
    ...plan,
    price: isAnnual ? plan.annualPrice : plan.price,
    buttonLink: isAnnual ? plan.annualPlanLink : plan.buttonLink,
    unit: isAnnual ? plan.annualUnit : plan.unit,
    // Other modifications for annual plans go here
  }));

  const togglePricing = (annual) => {
    setIsAnnual(annual);
  };

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div className="therapy-block">
          <div style={{ marginRight: "1rem", marginTop: "1rem" }}>
            <h2>Upgrade Your Meeting Experience</h2>
            <p style={{ fontSize: "1rem" }}>
              From Meeting Speech to Searchable Reach
            </p>

            <button
              className="usecase-btn"
              onClick={handleButtonClick}
              style={{ width: "18rem" }}
            >
              Try for free
            </button>

            <div
              className="center-text"
              style={{ width: "17rem", marginTop: "1rem" }}
            >
              <div>
                <img
                  src={hipaaOffice}
                  alt="HIPAA Official"
                  style={{ width: "100px", height: "60px" }}
                />
              </div>
              <div>
                <a
                  href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    background: "linear-gradient(to right, #0077be, #00a3e0)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  100% Compliant
                </a>
              </div>
            </div>
          </div>
          <img
            className="business-overview"
            src={productOverview}
            alt="Ember Copilot Overview"
          />
        </div>
        <div className="below-the-fold">
          <div className="time-savings-section">
            <h2>How Ember Works</h2>
            <div className="time-savings-grid">
              {timeSavingsData.map((item, index) => (
                <div key={index} className="time-savings-item">
                  <h3>{item.role}</h3>
                  {/* <p className="subtext">{item.subtext}</p> */}
                  {/* <p className="stat">{item.stat}</p> */}
                  <p className="description">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="therapy-pricing-block">
            <div className="plan-selection">
              <button
                onClick={() => togglePricing(false)}
                className={isAnnual ? "" : "active"}
              >
                Monthly
              </button>
              <button
                onClick={() => togglePricing(true)}
                className={isAnnual ? "active" : ""}
              >
                Yearly
              </button>
            </div>
            <div className="pricing-container">
              {modifiedPlans.map((plan) => (
                <div className="feature-column" key={plan.name}>
                  <div className="plan-header">{plan.name}</div>
                  <p className="plan-description">{plan.description}</p>
                  <p className="price">{plan.price}</p>
                  <p className="plan-unit" style={{ fontSize: "0.9rem" }}>
                    {plan.unit}
                  </p>
                  <ul
                    className="custom-pricing-bullet pricing-text"
                    style={{ minHeight: "4.5rem" }}
                  >
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <button
                    className={`price-btn variant-${plan.btncss}`}
                    onClick={() => window.open(plan.buttonLink, "_blank")}
                  >
                    {plan.buttonLabel}
                  </button>
                  <p className="plan-promo">{plan.buttonpromo}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="therapy-first-block"
            style={{ display: "block", textAlign: "center" }}
          >
            <h3>
              See what Ember users are saying about their experiences with Ember
            </h3>
            <div>
              <iframe
                className="yt-overview"
                src="https://www.youtube.com/embed/kN5ZoF9uvcQ?si=rWkorHbt1EEYAR7_"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Product Overview"
              ></iframe>
            </div>
            <a
              href="https://www.youtube.com/watch?v=PiRB2sIYSLs"
              target="_blank"
              rel="noreferrer"
              className="cta-link"
            >
              <p>View the full interview</p>
            </a>
          </div>
          <div className="logo-bar-section">
            <div className="logo-bar-header">Preferred by Professionals</div>
            <div className="logo-bar">
              <div className="logo-bar-content">
                <img
                  src={ntuh}
                  alt="NTUH (National Taiwan University Hospital)"
                />
                <img src={healthie} alt="Healthie" />
                {/* <img src={maine} alt="Maine Medical Center" /> */}
                <img src={dtf} alt="Din Tai Fung 鼎泰豐" />
                <img src={hante} alt="HantePay汉特支付" />
                <img src={sunmi} alt="Sunmi 商米科技" />
                <img
                  src={ntuh}
                  alt="NTUH (National Taiwan University Hospital)"
                />
                <img src={healthie} alt="Healthie" />
                {/* <img src={maine} alt="Maine Medical Center" /> */}
                <img src={dtf} alt="Din Tai Fung 鼎泰豐" />
                <img src={hante} alt="HantePay汉特支付" />
                <img src={sunmi} alt="Sunmi 商米科技" />
              </div>
            </div>
          </div>
          <div className="usecase-second-block" style={{ marginTop: "2rem" }}>
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Let Ember Remember, So You Can Focus on What Really Matters
              </h2>
              <div
                className="cta-btn-container"
                style={{ gap: "1rem", marginBottom: "0" }}
              >
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                </div>
                <p className="center-text">
                  <a
                    href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#fff" }}
                  >
                    HIPAA and SOC 2 Compliant
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="demo-section">
            <div className="demo-info">
              <h4>Book a demo</h4>
              <h3>Empower Your Team with Ember Copilot</h3>
              <p>
                Ready to take your practice to the next level? Bring your team
                on board and explore how Ember can revolutionize your workflow.
                From automatic session transcription to easy note submission,
                Ember ensures everyone on your team can work more efficiently.
                Experience the seamless integration with your EHR and see how
                Ember can empower your team to handle even the most complex
                tasks with ease.
              </p>
            </div>
            <div className="demo-form">
              <div className="demo-form">
                <iframe
                  className="airtable-embed"
                  src="https://airtable.com/embed/appcx1DPOq5OhbmfY/paggJb3LzcAtz8UcC/form"
                  frameBorder="0"
                  onmousewheel=""
                  title="Ember Copilot Book a Demo"
                  height="680"
                  style={{
                    background: "transparent",
                    width: "200%",
                  }}
                ></iframe>
                <style>
                  {`
                      @media (max-width: 768px) {
                        .airtable-embed {
                          width: 100% !important;
                          height: 740px;
                        }
                      }
                    `}
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;

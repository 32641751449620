// src/components/ConnectSlack.js

import React from "react";
import forSlack from "./../ForSlack.png";

const ConnectSlack = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <div style={{ textAlign: "center" }}>
          <h1>Ember Copilot for Slack</h1>
          <img
            className="product-overview"
            src={forSlack}
            alt="Product Overview"
          />
        </div>
        <div className="product-block product-spacing">
          <h2>Installation Steps</h2>
          <ol type="1">
            <li>
              <b>Log in to Ember AI: </b>Before installing, ensure you're logged
              into your{" "}
              <a
                className="cta-link"
                href="https://app.embercopilot.ai"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Ember AI
              </a>{" "}
              account . This step is crucial for linking your Ember AI content
              directly to your Slack workspace.
            </li>
            <li>
              <b>Install Ember Copilot for Slack: </b> Simply connect to Slack
              <a
                href="https://slack.com/oauth/v2/authorize?client_id=1186023623058.6833830899841&scope=channels:history,channels:join,channels:read,chat:write,groups:history,groups:read,im:history,im:read,incoming-webhook,mpim:read&user_scope=channels:read,groups:read,im:read,mpim:read"
                target="_blank"
                rel="noreferrer"
                className="cta-link"
              >
                {" "}
                here
              </a>
              . Following a successful installation, sharing updates and content
              across your selected Slack channels is a simple hit on the Slack
              icon.
            </li>
          </ol>
        </div>
        <div className="product-block product-spacing">
          <h2>Key Features</h2>
          <ul>
            <li>
              <b>Direct Integration: </b>Ember Copilot seamlessly connects with
              Slack, making content sharing instant and effortless.
            </li>
            <li>
              <b>Easy Sharing: </b>
              With just a click on the Slack icon, distribute your Ember records
              and updates to your selected channels.
            </li>
            <li>
              <b>Weekly Insights: </b>
              Receive comprehensive weekly reports detailing your team's
              accomplishments, fostering a culture of recognition and continuous
              improvement.
            </li>
          </ul>
        </div>
        <div className="product-block product-spacing">
          <h2>Discover a Smarter Way to Collaborate</h2>
          <p>
            With Ember Copilot for Slack, enhance your team's productivity,
            streamline communication, and celebrate success together. Embrace
            the future of team collaboration and ensure your team stays ahead of
            the curve.
          </p>
          <p>
            <i>
              Disclaimer: While Ember Copilot strives to provide accurate and
              useful information, users should be aware that due to the nature
              of language models, there may be instances of inaccuracies or
              unexpected responses. We recommend always verifying the generated
              content for accuracy and relevancy.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConnectSlack;

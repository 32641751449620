// src/components/Terms.js

import React from "react";

const Gift = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Give the Gift of Ember</h1>
        <p>
          <p>
            Gifting Ember Copilot is a thoughtful way to enhance your friends'
            or colleagues' work experience. Perfect for projects, sales calls,
            team brainstorming, interviews, meetings, or capturing ideas, the
            versatile plans offer advanced features that elevate their work.
          </p>
          <p>
            Click the link to send{" "}
            <a
              href="https://buy.stripe.com/14k15U2x2am34ikcMO"
              target="_blank"
              rel="noreferrer"
              className="cta-link"
            >
              Ember
            </a>{" "}
            or{" "}
            <a
              href="https://buy.stripe.com/9AQeWK6NifGn5mo28l"
              target="_blank"
              rel="noreferrer"
              className="cta-link"
            >
              Remember Plan
            </a>{" "}
            to your friends and colleagues, letting them enjoy the unmatched
            convenience and innovative features of Ember Copilot.
          </p>
        </p>
      </div>
    </div>
  );
};

export default Gift;

// src/components/DataPrivacyPolicy.js

import React from "react";

const DataPrivacyPolicy = () => {
  const data = [
    {
      category: "Profile or Contact Data",
      examples: ["First and last name", "Email"],
      thirdParties: [
        "Service Providers",
        "Analytics Partners",
        "Parties You Authorize, Access or Authenticate",
      ],
    },
    {
      category: "Payment Data",
      examples: [
        "Financial account information",
        "Payment card type",
        "Last 4 digits of payment card",
        "Billing address, phone number, and email",
      ],
      thirdParties: ["Service Providers"],
    },
    {
      category: "Device/IP Data",
      examples: [
        "IP address",
        "Device ID",
        "Type of device/operating system/browser used to access the Services",
        "Other applications that utilize the microphone function",
      ],
      thirdParties: [
        "Analytics Partners",
        "Parties You Authorize, Access or Authenticate",
      ],
    },
    {
      category: "Web Analytics",
      examples: [
        "Web page interactions",
        "Referring webpage/source through which you accessed the Services",
      ],
      thirdParties: [
        "Analytics Partners",
        "Parties You Authorize, Access or Authenticate",
      ],
    },
    {
      category: "Geolocation Data",
      examples: ["IP-address-based location information"],
      thirdParties: [
        "Analytics Partners",
        "Parties You Authorize, Access or Authenticate",
      ],
    },
    // {
    //   category: "Videos and Recording",
    //   examples: [
    //     "Videos or recordings of you",
    //     "Videos or recordings of your environment",
    //   ],
    //   thirdParties: [
    //     "Service Providers",
    //     "Parties You Authorize, Access or Authenticate",
    //   ],
    // },
    {
      category:
        "Other Identifying Information that You Voluntarily Choose to Provide",
      examples: [
        "Identifying information in emails, letters, texts, or other communication you send us",
        "Calendar data, including but not limited to meeting invitations, body text, and sender and recipients",
        // "Meeting data, including but not limited to attendees, audio, and transcriptions",
        "Any other identifying information you authorize Ember to access or elect to share with Ember",
        // "Any derivatives of such data, including but not limited to transcripts of conversations",
      ],
      thirdParties: [
        "Service Providers",
        "Parties You Authorize, Access or Authenticate",
      ],
    },
  ];

  // const dataSources = [
  //   {
  //     category: "You",
  //     details: [
  //       {
  //         subCategory: "When you provide such information directly to us.",
  //         points: [
  //           "When you create an account or use our interactive tools and Services.",
  //           "When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.",
  //           "When you send us an email or otherwise contact us.",
  //         ],
  //       },
  //       {
  //         subCategory:
  //           "When you use the Services and such information is collected automatically.",
  //         points: [
  //           'Through Cookies (defined in the "Tracking Tools and Opt-Out" section below).',
  //           "If you use a location-enabled browser, we may receive information about your location.",
  //           "If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.",
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "Third Parties",
  //     details: [
  //       {
  //         subCategory: "Vendors",
  //         points: [
  //           "We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.",
  //           "We may use vendors to obtain information to generate leads and create user profiles.",
  //         ],
  //       },
  //       {
  //         subCategory: "Third Party Accounts",
  //         points: [
  //           "If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.",
  //         ],
  //       },
  //     ],
  //   },
  // ];

  // const businessPurposes = [
  //   {
  //     category: "Providing, Customizing and Improving the Services",
  //     points: [
  //       "Creating and managing your account or other user profiles.",
  //       "Processing orders or other transactions; billing.",
  //       "Providing you with the products, services or information you request.",
  //       "Meeting or fulfilling the reason you provided the information to us.",
  //       "Providing support and assistance for the Services.",
  //       "Improving the Services, including testing, research, internal analytics and product development.",
  //       "Personalizing the Services, website content and communications based on your preferences.",
  //       "Doing fraud protection, security and debugging.",
  //       'Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act, as amended by the California Privacy Rights Act of 2020 (the "CCPA").',
  //     ],
  //   },
  //   {
  //     category: "Marketing the Services",
  //     points: ["Marketing and selling the Services."],
  //   },
  //   {
  //     category: "Corresponding with You",
  //     points: [
  //       "Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Ember or the Services.",
  //       "Sending emails and other communications according to your preferences or that display content that we think will interest you.",
  //     ],
  //   },
  //   {
  //     category: "Meeting Legal Requirements and Enforcing Legal Terms",
  //     points: [
  //       "Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.",
  //       "Protecting the rights, property or safety of you, Ember or another party.",
  //       "Enforcing any agreements with you.",
  //       "Responding to claims that any posting or other content violates third-party rights.",
  //       "Resolving disputes.",
  //     ],
  //   },
  //   {
  //     category: "Notice of Additional Categories or Purposes",
  //     points: [
  //       "We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.",
  //     ],
  //   },
  // ];

  const sections = [
    {
      id: "description-acceptance",
      title: "Description of Users and Acceptance of Terms",
      content: `
        <p>This Privacy Policy applies to:</p>
        <ul>
          <li>Visitors to our Website, who view only publicly-available content</li>
          <li>Customers who have signed up to access and use our App</li>
          <li>Customer's employees and contractors authorized to access and use our App</li>
        </ul>
        <p>By visiting our Website, you are agreeing to the terms of this Privacy Policy and the <a
              href="/terms"
              className="cta-link"
              target="_blank"
              rel="noreferrer"
            > Terms of Service</a>.</p>
        <p>By accessing and/or using the App, each Customer and Authorized User is agreeing to the terms of this Privacy Policy and the Terms of Service.</p>
      `,
    },
    {
      id: "information-collection",
      title: "The Information We Collect and/or Receive",
      content: `
        <p>In the course of operating the Website and the App, we collect and/or receive the following types of information:</p>
        <ol>
          <li>
            <b>Contact Information:</b> When you contact us, you may provide your name, email address, phone number, company name, job title, and any other information you choose to share.
          </li>
          <li>
            <b>Account Information:</b> To create an account on our App, you will need to provide your email address and create a password.
          </li>
          <li>
            <b>Your Data:</b> Any data you provide while using the App will be handled in accordance with this Privacy Policy.
          </li>
          <li>
            <b>Automatically Collected Information:</b> We use various technologies (such as cookies, pixels, web beacons) to automatically collect certain information about your online activity, device, and usage of our Website and App.
          </li>
          <li>
            <b>Third-Party Analytics:</b> We may use third-party analytics services (such as Google Analytics) to evaluate your use of the Website and App.
          </li>     
        </ol>
      `,
    },
    {
      id: "information-use",
      title: "How We Use and Share the Information",
      content: `
        <p>We may use and share your personal information as follows:</p>
        <ul>
          <li>To provide and improve the Website and App</li>
          <li>To communicate with you about our products and services</li>
          <li>To monitor, analyze, and improve our Website and App</li>
          <li>To fulfill your requests and process transactions</li>          
          <li>To protect the safety and security of our Website, App, and users</li>
          <li>To comply with legal obligations and enforce our agreements</li>
          <li>To share with third-party service providers who assist us in operating our business</li>          
        </ul>
        <p>We take reasonable measures to ensure that any party receiving personal information from us protects that information and uses it only for the purposes outlined in this Privacy Policy.</p>
      `,
    },
    {
      id: "accessing-modifying",
      title:
        "Accessing and Modifying Information and Communication Preferences",
      content: `
        <p><b>Update Information:</b> You may correct, update, or delete your personal information by contacting us. Some information can be updated directly in your App account.</p>        
        <p><b>Cookie Management:</b> Most browsers allow you to remove or reject cookies. Visit www.allaboutcookies.org for more information on managing cookies.</p>
      `,
    },
    {
      id: "data-protection",
      title: "How We Protect the Information",
      content: `
        <p>We take commercially reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no Internet transmission is 100% secure, and we cannot guarantee the security of your information.</p>
      `,
    },
    {
      id: "external-sites",
      title: "External Sites",
      content: `
        <p>Our Website and App may contain links to external third-party websites. We are not responsible for the privacy practices or content of these External Sites. Please check the applicable third-party privacy policy when visiting any External Sites.</p>
      `,
    },
    {
      id: "children",
      title: "Children",
      content: `
        <p>We do not knowingly collect personal information from children under the age of 18. If you are under 18, please do not provide any personal information to us. If you believe a child under 18 has provided personal information to us, please contact us to have it removed.</p>
      `,
    },
    {
      id: "non-us-residents",
      title: "Important Notice to Non-U.S. Residents",
      content: `
        <p>Our Website and App are operated in the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to, processed, and used in the United States. By using our Website or App, you consent to this transfer and processing of your personal information.</p>
      `,
    },
    {
      id: "changes",
      title: "Changes to This Privacy Policy",
      content: `
        <p>We may change this Privacy Policy from time to time. Any changes will be posted on the Website and App. Your continued use of our Website or App after we make changes indicates your acceptance of those changes.</p>
      `,
    },
    {
      id: "contact",
      title: "Contact Us",
      content: `
        <p>If you have any questions about this Privacy Policy or to report a privacy issue, please contact us:</p>
        <ul>
          <li>Email: support@embercopilot.ai</li>         
        </ul>
      `,
    },
  ];

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Privacy Policy</h1>

        <div className="overview-text">
          <p>
            Ember is committed to protecting the privacy of our users. As a
            company based in the United States, we adhere strictly to U.S.
            privacy laws and regulations, which are designed to safeguard
            personal information. This Privacy Policy explains how we collect,
            use, and share information in connection with our website, our
            proprietary software as a service (SaaS) platform, and your
            interactions with us.
          </p>
        </div>

        <div className="table-of-contents">
          <h3>Table of Contents</h3>
          <ul>
            {sections.map((section) => (
              <li key={section.id}>
                <a href={`#${section.id}`}>{section.title}</a>
              </li>
            ))}
          </ul>
        </div>

        {sections.map((section) => (
          <div key={section.id}>
            <a href={`#${section.id}`} className="section-title-link">
              <h3 id={section.id}>{section.title}</h3>
            </a>
            <div dangerouslySetInnerHTML={{ __html: section.content }} />
          </div>
        ))}

        <a href="#personal-data" className="section-title-link">
          <h3 id="personal-data">Personal Data We Collect</h3>
        </a>
        <p>
          This chart details the categories of Personal Data that we collect:
        </p>
        <div className="data-privacy-table">
          <table>
            <thead>
              <tr>
                <th>Category of Personal Data</th>
                <th>Examples of Personal Data We Collect</th>
                <th>
                  Categories of Third Parties With Whom We Share this Personal
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.category}</td>
                  <td>
                    <ul>
                      {item.examples.map((example, i) => (
                        <li key={i}>{example}</li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {item.thirdParties.map((party, i) => (
                        <li key={i}>{party}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <a href="#data-protection-rights" className="section-title-link">
          <h3 id="data-protection-rights">Your Data Protection Rights</h3>
        </a>
        <p>
          You have the following rights concerning your personal data that we
          hold:
        </p>
        <ul>
          <li>
            <b>Right to access: </b>
            You have the right to request copies of your personal data from us.
          </li>
          <li>
            <b>Right to rectification: </b>
            You have the right to request that we correct any information you
            believe is inaccurate. You also have the right to request us to
            complete information you believe is incomplete.
          </li>
          <li>
            <b>Right to erasure: </b>
            You have the right to request that we erase your personal data,
            under certain conditions.
          </li>
          <li>
            <b>Right to restrict processing: </b>
            You have the right to request that we restrict the processing of
            your personal data, under certain conditions.
          </li>
          <li>
            <b>Right to object to processing: </b>
            You have the right to object to our processing of your personal
            data, under certain conditions.
          </li>
          <li>
            <b>Right to data portability: </b>
            You have the right to request that we transfer the data that we have
            collected to another organization, or directly to you, under certain
            conditions.
          </li>
        </ul>
        <p>
          If you make a request, we have one month to respond to you. Please
          contact us through support@embercopilot.ai if you wish to exercise any
          of these rights.
        </p>

        <div className="google-api-use">
          <a href="#google-api-compliance" className="section-title-link">
            <h3 id="google-api-compliance">Google API Data</h3>
          </a>
          <p>
            To the extent we collect Personal Data about you through use of a
            Google API, our use and transfer of such Personal Data to any other
            third-party will be in compliance with the{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy?sjid=2339832302065318022-NA#additional_requirements_for_specific_api_scopes"
              className="cta-link"
              target="_blank"
              rel="noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use Requirements.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataPrivacyPolicy;

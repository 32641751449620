// src/components/HelpCenter.js

import React, { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ShareThis from "./../ShareThis.png";
import oneClickEmail from "./../OneClickEmail.png";
import enhancedTranslation from "./../Translation.png";
import uploadAudio from "./../UploadAudio.png";
import slackChannels from "./../slack-channels.png";
import weeklySummary from "./../WeeklySummary.png";
import createTeam from "./../create-team.png";
// import salesUseCase from "./../SalesUseCase.png";
import generateMode from "./../GenerateMode.png";
import outlineMode from "./../Outline.png";
// import PitchingMode from "./../PitchingMode.png";
import ActionItemsMode from "./../ActionItemsMode.png";
import editTitle from "./../EditTitle.png";
import regenerateMode from "./../Regenerate.png";
import editTranscript from "./../EditTranscript.png";
import keywords from "./../Keywords.png";
import downloadAudio from "./../downloadableaudio.png";

const HelpCenterNote = ({ date, title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="release-note-container">
      <div
        className="release-note-header"
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer" }}
      >
        <div className="release-toggle">
          <ArrowForwardIosSharpIcon
            style={{ width: "1rem" }}
            className={expanded ? "rotate-icon" : ""}
          />
        </div>

        <div className="release-note-title">{title}</div>
      </div>

      {expanded && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

const HelpCenter = () => {
  const zoomContent = (
    <>
      <ol>
        <li>
          <b>Join the Call: </b>Ensure that Ember is added to the call.
        </li>
        <li>
          <b>Grant Permissions: </b>The meeting host needs to grant Ember
          permission. If the permission dialog doesn’t appear, ensure you’re
          logged into the Zoom host account. This issue is common, especially if
          the waiting room feature is disabled.
        </li>
        <li>
          <b>Enable Captions: </b>In Zoom’s settings, activate the "Allow Closed
          Captioning for this meeting" option under "Show Captions."
        </li>
        <li>
          <b>Seek Support: </b>If issues persist, contact the Ember Support Team
          through the 'Contact Us' option in your App settings or{" "}
          <a href="mailto: support@embercopilot.ai" className="cta-link">
            email us
          </a>{" "}
          for further assistance.
        </li>
      </ol>
    </>
  );
  const isEmberSafe = (
    <>
      <p>
        Yes, data security is our top priority. We are proud to be both HIPAA
        and SOC 2 compliant, ensuring the highest standards of data protection
        and privacy. Our commitment to security goes even further with our
        Confidential Mode feature, providing an additional layer of privacy for
        your sensitive information.
      </p>
    </>
  );

  const doesEmberTrainData = (
    <>
      <p>
        No, Ember does not save or retrain its model using user data. Your
        information and interactions remain private and are not used to refine
        or update Ember’s algorithms.
      </p>
    </>
  );

  const confidentialMode = (
    <>
      <p>
        Confidential Mode enhances the privacy and security of your data by
        ensuring that sensitive information is handled with additional
        safeguards during processing and storage.
      </p>
    </>
  );

  const turnOnConfidentialMode = (
    <>
      <p>
        You can activate Confidential Mode by navigating to the settings in your
        application and enabling the option for Confidential Mode. This ensures
        that your data is processed with enhanced security measures.
      </p>
    </>
  );

  const progressNoteContent = (
    <>
      <p>
        Yes, Ember can generate insurance-friendly SOAP Notes. Simply review and
        submit these notes as needed, streamlining your documentation process.
      </p>
    </>
  );

  const OtherLanguagesContent = (
    <>
      <div>
        <p>
          Yes, Ember supports a wide range of languages, offering functionality
          in over 45 languages for recordings. Users can speak in any language,
          including mixes of different languages. For transcripts, you can
          choose from several options including English, Spanish, Chinese,
          Japanese, and Korean.
        </p>
        <p>Additional languages are available upon request.</p>
      </div>
      <img
        className="long-recording"
        src={enhancedTranslation}
        alt="Support 45 Languages"
      />
    </>
  );

  const recordAnythingContent = (
    <>
      <div>
        <p>
          You can use Ember to record a variety of audio content including
          meetings, conversations, conferences, personal worklogs, and journal
          entries. Ember is designed to handle real conversations effectively,
          helping you capture and transcribe these discussions accurately.
        </p>
        <p>However, we do not recommend using Ember to record:</p>
        <ul>
          <li>
            <b>Music (Lyrics)</b>
          </li>
          <li>
            <b>Dramatic performances</b>
          </li>
          <li>
            <b>Commercials</b>
          </li>
          <li>
            <b>Misleading or test content</b>
          </li>
        </ul>
        <p>
          Recording such content can potentially lead to errors in tone
          recognition and transcription inaccuracies. This might result in
          transcripts that do not reflect the intended phrasing or accuracy you
          expect.
        </p>
        <p>
          For best results, stick to recording genuine conversational content
          with Ember.
        </p>
      </div>
    </>
  );

  const whatToRecordContent = (
    <>
      <div>
        <p>
          Ember is optimized for recording and transcribing various types of
          spoken content.
        </p>
        <p>Here are the best uses for Ember:</p>
        <ol>
          <li>
            <b>Clinical Sessions: </b>Record and transcribe sessions with our
            HIPAA-compliant medical scribe.
          </li>
          <li>
            <b>Meetings: </b>Capture detailed notes and discussions from your
            business or team meetings.
          </li>
          <li>
            <b>Conversations: </b>Record one-on-one or group conversations to
            revisit later or share with others who couldn’t attend.
          </li>
          <li>
            <b>Conferences: </b>Use Ember to document keynotes, panel
            discussions, and breakout sessions, ensuring you don’t miss any
            valuable insights.
          </li>
          <li>
            <b>Personal Worklogs: </b>Keep track of your daily tasks and
            thoughts by recording your personal worklogs.
          </li>
        </ol>
        <p>
          <b>Not sure where to start? </b>If you're new to using Ember, consider
          starting by recording your plans for the week to get a feel for how
          Ember works. Alternatively, let Ember join your next internal meeting
          to start capturing discussions right away.
        </p>
        <p>
          By focusing on these types of content, you can leverage Ember’s
          capabilities to enhance your productivity and ensure accurate, useful
          transcriptions of your spoken words.
        </p>
      </div>
    </>
  );

  const LinkSharingContent = (
    <>
      <div>
        <p>
          Use the Link Sharing feature to generate a simple link to your script
          and analysis. This link can be shared with anyone, allowing them easy
          access to the information.
        </p>
      </div>
      <img className="long-recording" src={ShareThis} alt="Link Sharing" />
    </>
  );

  const emailThisContent = (
    <>
      <div>
        <p>
          With Enhanced Email Delivery, you can select and send directly to your
          email with just one click.
        </p>
      </div>
      <img
        className="long-recording"
        src={oneClickEmail}
        alt="One-Click Email Delivery"
      />
    </>
  );

  const downloadAudioContent = (
    <>
      <div>
        <p>
          Download the audio files of your recordings for deeper analysis or
          sharing using our Downloadable Recording Audio feature.
        </p>
      </div>
      <img
        className="long-recording"
        src={downloadAudio}
        alt="Download Audio Recordings"
      />
    </>
  );

  const slackContent = (
    <>
      <div>
        <p>
          With our{" "}
          <a href="/connect-slack" className="cta-link" target="_blank">
            Slack Integration
          </a>
          , you can share content directly from Ember to selected Slack
          channels. Just click the Slack icon after creating your content.
        </p>
      </div>
      <img
        className="create-team"
        src={slackChannels}
        alt="Share to Slack Channels"
      />
    </>
  );

  const weeklySummaryContent = (
    <>
      <div>
        <p>
          Access the Weekly Snippet by engaging with Ember and making at least
          three entries every week. You'll find the new Weekly Summary at the
          top of the History page every Friday.
        </p>
      </div>
      <img
        className="long-recording"
        src={weeklySummary}
        alt="Weekly Summary"
      />
    </>
  );

  const createTeamContent = (
    <>
      <div>
        <p>
          Enhance team collaboration using the Create Your Group feature in
          Ember. Team members can share recordings directly on the team page,
          streamlining communication.
        </p>
      </div>
      <img className="create-team" src={createTeam} alt="Create Team" />
    </>
  );

  const transcriptContent = (
    <>
      <div>
        <p>
          Ember provides transcripts and summaries seconds after the meeting
          ends, aiding quick review and follow-up.
        </p>
      </div>
    </>
  );

  // const salesCallContent = (
  //   <>
  //     <div>
  //       <p>
  //         Use <b>Sales Call</b>, <b>Summary</b>, and <b>Pitching Analysis</b> to
  //         capture detailed insights and document interactions effectively during
  //         sales or customer engagements.
  //       </p>
  //     </div>
  //     <img
  //       className="long-recording"
  //       src={salesUseCase}
  //       alt="Sales Call and Customer Interviews"
  //     />
  //   </>
  // );

  const lowWifiContent = (
    <>
      <div>
        <p>
          Upload audio files directly to Ember using the Upload Audio for
          Offline Recordings feature. This lets you generate scripts and
          analyses without a live internet connection.
        </p>
      </div>
      <img
        className="long-recording"
        src={uploadAudio}
        alt="Upload Audio Recordings"
      />
    </>
  );

  const organizeContent = (
    <>
      <div>
        <ul>
          <li>
            <b>Outline</b> transforms your discussions into organized bullet
            points, making it easier to quickly sort through information.
          </li>
          <li>
            <b>Presenting</b> is great for summarizing and organizing
            information for presentations.
          </li>
          <li>
            <b>Concise</b> condenses recordings to their most essential elements
            for clear communication.
          </li>
        </ul>
      </div>
      <img
        className="create-team"
        src={outlineMode}
        alt="Organize my content"
      />
    </>
  );

  const changeModeContent = (
    <>
      <div>
        <p>
          Yes, you can choose or regenerate a more suitable analysis after
          creating an entry.
        </p>
      </div>
      <img className="create-team" src={generateMode} alt="Generate Mode" />
    </>
  );

  const differentModeContent = (
    <>
      <div>
        Ember offers several AI analysis tailored to various needs:
        <ul>
          <li>
            <b>Polishing: </b>Enhances wording and flow by removing filler words
            and errors.
          </li>
          <li>
            <b>Presenting: </b>Summarizes and organizes information, ideal for
            presentations.
          </li>
          <li>
            <b>Concise: </b>Condenses content to essential elements.
          </li>
          {/* <li>
            <b>Sales Call: </b>Focuses on strategic aspects of sales pitches,
            documenting responses and objections.
          </li> */}
        </ul>
      </div>
    </>
  );

  const actionItemModeContent = (
    <>
      <div>
        <p>
          <b>Action Item</b> identifies tasks from your recordings and organizes
          them into a categorized list.
        </p>
      </div>
      <img className="long-recording" src={ActionItemsMode} alt="Action Item" />
    </>
  );

  // const pitchingModeContent = (
  //   <>
  //     <div>
  //       <p>
  //         <b>Pitching Analysis</b> evaluates your pitch on various aspects like
  //         clarity and pace, providing detailed feedback and suggestions for
  //         improvement.
  //       </p>
  //     </div>
  //     <img className="long-recording" src={PitchingMode} alt="Pitching Mode" />
  //   </>
  // );

  const editTitleContent = (
    <>
      <div>
        <p>
          Yes, titles generated by the AI are fully editable, allowing you to
          modify them directly for easier recognition and searchability.
        </p>
      </div>
      <img className="long-recording" src={editTitle} alt="Edit Title" />
    </>
  );

  const regenerateContent = (
    <>
      <div>
        <p>
          Yes, if the results from a selected mode don't meet your expectations,
          you can easily regenerate a mode for better outcomes. This ensures
          that your final content aligns with your needs.
        </p>
      </div>
      <img className="create-team" src={regenerateMode} alt="Create Team" />
    </>
  );

  const editTranscriptContent = (
    <>
      <div>
        <p>
          Yes, you can edit transcripts directly. By providing feedback through
          thumbs up and thumbs down reactions, you help the Ember Copilot learn
          from your edits, improving the accuracy and relevance of future
          outputs.
        </p>
      </div>
      <img
        className="long-recording"
        src={editTranscript}
        alt="Edit Transcript"
      />
    </>
  );

  const keywordContent = (
    <>
      <div>
        <p>
          Yes, you can add custom words and names in the settings. The Custom
          Keywords feature enhances transcription accuracy by including your
          frequently used keywords and acronyms, allowing for more precise and
          effortless communication.
        </p>
      </div>
      <img className="create-team" src={keywords} alt="Add names or keywords" />
    </>
  );

  const giveFeedbackContent = (
    <>
      <div>
        <p>
          We value all user feedback at Ember. To provide feedback, please click
          the <b>Ember icon</b> located in the lower right corner of your
          screen. This opens a direct channel to our team, enabling efficient
          communication and quick responses to your questions or feedback.
        </p>
        <p>
          Your feedback is incredibly important to us at Ember. We look forward
          to hearing from you!
        </p>
      </div>
    </>
  );

  const reportBugsContent = (
    <>
      <div>
        <p>
          If you encounter any bugs, kindly report them by clicking the{" "}
          <b>Ember icon</b> in the lower right corner of your screen. This opens
          a direct channel to our team. We will promptly address any issues to
          enhance your user experience.
        </p>
      </div>
    </>
  );

  const requestFeaturesContent = (
    <>
      <div>
        <p>
          To suggest new features, please click the <b>Ember icon</b> in the
          lower right corner of your screen to connect directly with our team.
          We value your input and are always excited to consider how we can
          adapt Ember to better serve your needs.
        </p>
      </div>
    </>
  );

  const identifySpeakerContent = (
    <>
      <div>
        <p>
          Our new Speaker Diarization feature can accurately identify and
          differentiate speakers within any audio or video recording. This
          enhances the precision of your transcripts and improves analyses and
          feedback by attributing dialogue to the correct speakers.
        </p>
      </div>
    </>
  );

  const playbackContent = (
    <>
      <div>
        <p>
          Yes, Ember now offers a Video Playback feature, allowing you to
          conveniently view meeting videos directly within the platform.
        </p>
      </div>
    </>
  );

  const downloadVideoContent = (
    <>
      <div>
        <p>
          Yes, along with playback, you can easily download videos of your
          meetings for offline viewing and record-keeping. This ensures you have
          access to your meeting content whenever you need it, providing
          flexibility in how you review and use your recordings.
        </p>
      </div>
    </>
  );

  const searchKeywordContent = (
    <>
      <div>
        <p>
          Utilize our{" "}
          <a
            href="https://youtu.be/7aEi0-AkkeA?si=X8JVcbH-lfam27ox"
            target="_blank"
            rel="noreferrer"
            className="cta-link"
          >
            Ask Ember
          </a>{" "}
          feature, an AI-driven tool that allows you to delve into past meetings
          for insights and analyses. Simply pose your questions to Ember Copilot
          to receive detailed answers, helping you extract maximum value from
          your recorded sessions.
        </p>
      </div>
    </>
  );

  const analyzeMultipleContent = (
    <>
      <div>
        <p>
          <a
            href="https://youtu.be/7aEi0-AkkeA?si=X8JVcbH-lfam27ox"
            target="_blank"
            rel="noreferrer"
            className="cta-link"
          >
            Ask Ember
          </a>{" "}
          also supports querying across multiple meetings. This feature lets you
          gather comprehensive insights by analyzing content from various
          sessions, enhancing your decision-making based on extensive data.
        </p>
      </div>
    </>
  );

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Security and Privacy</h1>
        <HelpCenterNote title="Is Ember safe to use?">
          {isEmberSafe}
        </HelpCenterNote>
        <HelpCenterNote title="Does Ember save and retrain its model with user data?">
          {doesEmberTrainData}
        </HelpCenterNote>
        <HelpCenterNote title="What is Confidential Mode?">
          {confidentialMode}
        </HelpCenterNote>
        <HelpCenterNote title="How do I turn on Confidential Mode?">
          {turnOnConfidentialMode}
        </HelpCenterNote>
        <h1>Communication and Collaboration</h1>
        <HelpCenterNote title="What should I record with Ember?">
          {whatToRecordContent}
        </HelpCenterNote>
        <HelpCenterNote title="Does Ember support languages other than English?">
          {OtherLanguagesContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I record anything with Ember?">
          {recordAnythingContent}
        </HelpCenterNote>
        <HelpCenterNote title="How can I share transcripts with anyone?">
          {LinkSharingContent}
        </HelpCenterNote>
        <HelpCenterNote title="How can I quickly email the transcripts and summaries?">
          {emailThisContent}
        </HelpCenterNote>
        <HelpCenterNote title="How can I download audio recording?">
          {downloadAudioContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I share updates on Slack?">
          {slackContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I get a Weekly Summary?">
          {weeklySummaryContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I improve team collaboration?">
          {createTeamContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I download videos of my meetings?">
          {downloadVideoContent}
        </HelpCenterNote>
        <h1>Enhancements in Meeting and Analysis Tools</h1>
        <HelpCenterNote title="How long does it take to create transcripts for long meetings?">
          {transcriptContent}
        </HelpCenterNote>
        {/* <HelpCenterNote title="How can I enhance sales pitches or customer interviews?">
          {salesCallContent}
        </HelpCenterNote> */}
        <HelpCenterNote title="What if I have a recording from a low connectivity area?">
          {lowWifiContent}
        </HelpCenterNote>
        <HelpCenterNote title="How can I quickly organize my content?">
          {organizeContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I change the analysis after recording?">
          {changeModeContent}
        </HelpCenterNote>
        <HelpCenterNote title="How can I identify who is speaking in my transcripts?">
          {identifySpeakerContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I playback videos of my meetings?">
          {playbackContent}
        </HelpCenterNote>
        <HelpCenterNote title="What should I do if Ember does not capture conversations in my Zoom meeting?">
          {zoomContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can Ember create documentation in specific formats? Like insurance-friendly formats?">
          {progressNoteContent}
        </HelpCenterNote>
        <h1>AI Analysis for Conversational Insights</h1>
        <HelpCenterNote title="What are the different AI analysis available?">
          {differentModeContent}
        </HelpCenterNote>
        <HelpCenterNote title="How does Ember manage tasks from recordings?">
          {actionItemModeContent}
        </HelpCenterNote>
        {/* <HelpCenterNote title="How does Ember help improve my pitching skills?">
          {pitchingModeContent}
        </HelpCenterNote> */}
        <HelpCenterNote title="Can I rename the title?">
          {editTitleContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I regenerate the script I got?">
          {regenerateContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I edit the generated transcripts?">
          {editTranscriptContent}
        </HelpCenterNote>
        <HelpCenterNote title="Can I add my own words or names?">
          {keywordContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I search for specific information across past meetings?">
          {searchKeywordContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I get analysis from multiple meetings?">
          {analyzeMultipleContent}
        </HelpCenterNote>
        <h1>Feedback and Reporting</h1>
        <HelpCenterNote title="How do I give feedback to the Ember team?">
          {giveFeedbackContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I report bugs to the Ember team?">
          {reportBugsContent}
        </HelpCenterNote>
        <HelpCenterNote title="How do I request features to the Ember team?">
          {requestFeaturesContent}
        </HelpCenterNote>
      </div>
    </div>
  );
};

export default HelpCenter;

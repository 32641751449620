// src/components/Terms.js

import React from "react";

const ContactUs = () => {
  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container privacy-wrapper">
        <h1>Contact us</h1>
        <p>
          <p>
            At Ember Copilot, we're committed to delivering outstanding support.
            Our aim is to make your experience smooth and efficient. If you have
            questions or face any issues, we're ready to assist quickly and
            efficiently.
          </p>
          <h2>Feedback and Support</h2>
          <p>
            For both support needs and feedback, email us at{" "}
            <a className="cta-link" href="mailto:support@embercopilot.ai">
              support@embercopilot.ai
            </a>
            . Our dedicated team is ready from 8 AM to 8 PM PT, Monday to
            Friday, aiming to respond within 12 hours. Your input is invaluable
            to us, helping shape the future of Ember Copilot.
          </p>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
